/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingDataAddonsDataInner } from './BookingDataAddonsDataInner';
import {
  BookingDataAddonsDataInnerFromJSON,
  BookingDataAddonsDataInnerFromJSONTyped,
  BookingDataAddonsDataInnerToJSON
} from './BookingDataAddonsDataInner';
import type { BookingDataPricingCategoriesDataInner } from './BookingDataPricingCategoriesDataInner';
import {
  BookingDataPricingCategoriesDataInnerFromJSON,
  BookingDataPricingCategoriesDataInnerFromJSONTyped,
  BookingDataPricingCategoriesDataInnerToJSON
} from './BookingDataPricingCategoriesDataInner';
import type { BookingDataProductData } from './BookingDataProductData';
import {
  BookingDataProductDataFromJSON,
  BookingDataProductDataFromJSONTyped,
  BookingDataProductDataToJSON
} from './BookingDataProductData';

/**
 *
 * @export
 * @interface BookingData
 */
export interface BookingData {
  /**
   *
   * @type {Array<BookingDataPricingCategoriesDataInner>}
   * @memberof BookingData
   */
  pricing_categories_data: Array<BookingDataPricingCategoriesDataInner>;
  /**
   *
   * @type {Array<BookingDataAddonsDataInner>}
   * @memberof BookingData
   */
  addons_data?: Array<BookingDataAddonsDataInner>;
  /**
   *
   * @type {BookingDataProductData}
   * @memberof BookingData
   */
  product_data: BookingDataProductData;
  /**
   *
   * @type {BookingDataProductData}
   * @memberof BookingData
   */
  product_option_data: BookingDataProductData;
  /**
   *
   * @type {string}
   * @memberof BookingData
   */
  formatted_time_slot_date_time: string;
  /**
   *
   * @type {string}
   * @memberof BookingData
   */
  expires_at: string;
  /**
   *
   * @type {string}
   * @memberof BookingData
   */
  hash_code: string;
  /**
   *
   * @type {number}
   * @memberof BookingData
   */
  total_price: number;
  /**
   *
   * @type {number}
   * @memberof BookingData
   */
  total_price_pre_promo: number;
  /**
   *
   * @type {string}
   * @memberof BookingData
   */
  cancellation_policy_description?: string;
}

/**
 * Check if a given object implements the BookingData interface.
 */
export function instanceOfBookingData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_categories_data' in value;
  isInstance = isInstance && 'product_data' in value;
  isInstance = isInstance && 'product_option_data' in value;
  isInstance = isInstance && 'formatted_time_slot_date_time' in value;
  isInstance = isInstance && 'expires_at' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'total_price' in value;
  isInstance = isInstance && 'total_price_pre_promo' in value;

  return isInstance;
}

export function BookingDataFromJSON(json: any): BookingData {
  return BookingDataFromJSONTyped(json, false);
}

export function BookingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_categories_data: (json['pricing_categories_data'] as Array<any>).map(
      BookingDataPricingCategoriesDataInnerFromJSON
    ),
    addons_data: !exists(json, 'addons_data')
      ? undefined
      : (json['addons_data'] as Array<any>).map(BookingDataAddonsDataInnerFromJSON),
    product_data: BookingDataProductDataFromJSON(json['product_data']),
    product_option_data: BookingDataProductDataFromJSON(json['product_option_data']),
    formatted_time_slot_date_time: json['formatted_time_slot_date_time'],
    expires_at: json['expires_at'],
    hash_code: json['hash_code'],
    total_price: json['total_price'],
    total_price_pre_promo: json['total_price_pre_promo'],
    cancellation_policy_description: !exists(json, 'cancellation_policy_description')
      ? undefined
      : json['cancellation_policy_description']
  };
}

export function BookingDataToJSON(value?: BookingData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_categories_data: (value.pricing_categories_data as Array<any>).map(
      BookingDataPricingCategoriesDataInnerToJSON
    ),
    addons_data:
      value.addons_data === undefined
        ? undefined
        : (value.addons_data as Array<any>).map(BookingDataAddonsDataInnerToJSON),
    product_data: BookingDataProductDataToJSON(value.product_data),
    product_option_data: BookingDataProductDataToJSON(value.product_option_data),
    formatted_time_slot_date_time: value.formatted_time_slot_date_time,
    expires_at: value.expires_at,
    hash_code: value.hash_code,
    total_price: value.total_price,
    total_price_pre_promo: value.total_price_pre_promo,
    cancellation_policy_description: value.cancellation_policy_description
  };
}
