/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingDataAddonsDataInner
 */
export interface BookingDataAddonsDataInner {
  /**
   *
   * @type {string}
   * @memberof BookingDataAddonsDataInner
   */
  addon_id: string;
  /**
   *
   * @type {string}
   * @memberof BookingDataAddonsDataInner
   */
  addon_name: string;
  /**
   *
   * @type {string}
   * @memberof BookingDataAddonsDataInner
   */
  addon_description: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingDataAddonsDataInner
   */
  added_to: BookingDataAddonsDataInnerAddedToEnum;
  /**
   *
   * @type {number}
   * @memberof BookingDataAddonsDataInner
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataAddonsDataInner
   */
  individual_price: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataAddonsDataInner
   */
  individual_price_pre_promo: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataAddonsDataInner
   */
  total_price: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataAddonsDataInner
   */
  total_price_pre_promo: number;
}

/**
 * @export
 */
export const BookingDataAddonsDataInnerAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type BookingDataAddonsDataInnerAddedToEnum =
  (typeof BookingDataAddonsDataInnerAddedToEnum)[keyof typeof BookingDataAddonsDataInnerAddedToEnum];

/**
 * Check if a given object implements the BookingDataAddonsDataInner interface.
 */
export function instanceOfBookingDataAddonsDataInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'addon_id' in value;
  isInstance = isInstance && 'addon_name' in value;
  isInstance = isInstance && 'addon_description' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'quantity' in value;
  isInstance = isInstance && 'individual_price' in value;
  isInstance = isInstance && 'individual_price_pre_promo' in value;
  isInstance = isInstance && 'total_price' in value;
  isInstance = isInstance && 'total_price_pre_promo' in value;

  return isInstance;
}

export function BookingDataAddonsDataInnerFromJSON(json: any): BookingDataAddonsDataInner {
  return BookingDataAddonsDataInnerFromJSONTyped(json, false);
}

export function BookingDataAddonsDataInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingDataAddonsDataInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    addon_id: json['addon_id'],
    addon_name: json['addon_name'],
    addon_description: json['addon_description'],
    added_to: json['added_to'],
    quantity: json['quantity'],
    individual_price: json['individual_price'],
    individual_price_pre_promo: json['individual_price_pre_promo'],
    total_price: json['total_price'],
    total_price_pre_promo: json['total_price_pre_promo']
  };
}

export function BookingDataAddonsDataInnerToJSON(value?: BookingDataAddonsDataInner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    addon_id: value.addon_id,
    addon_name: value.addon_name,
    addon_description: value.addon_description,
    added_to: value.added_to,
    quantity: value.quantity,
    individual_price: value.individual_price,
    individual_price_pre_promo: value.individual_price_pre_promo,
    total_price: value.total_price,
    total_price_pre_promo: value.total_price_pre_promo
  };
}
