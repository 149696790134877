/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RemoveFromCartRequest
 */
export interface RemoveFromCartRequest {
  /**
   *
   * @type {string}
   * @memberof RemoveFromCartRequest
   */
  locale_code: string;
  /**
   * Currency code selected in the client application. Prices in the response are returned in the selected response_currency_code
   * @type {string}
   * @memberof RemoveFromCartRequest
   */
  response_currency_code: string;
  /**
   *
   * @type {string}
   * @memberof RemoveFromCartRequest
   */
  shopping_cart_hash: string;
  /**
   *
   * @type {string}
   * @memberof RemoveFromCartRequest
   */
  booking_hash: string;
}

/**
 * Check if a given object implements the RemoveFromCartRequest interface.
 */
export function instanceOfRemoveFromCartRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'response_currency_code' in value;
  isInstance = isInstance && 'shopping_cart_hash' in value;
  isInstance = isInstance && 'booking_hash' in value;

  return isInstance;
}

export function RemoveFromCartRequestFromJSON(json: any): RemoveFromCartRequest {
  return RemoveFromCartRequestFromJSONTyped(json, false);
}

export function RemoveFromCartRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveFromCartRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    response_currency_code: json['response_currency_code'],
    shopping_cart_hash: json['shopping_cart_hash'],
    booking_hash: json['booking_hash']
  };
}

export function RemoveFromCartRequestToJSON(value?: RemoveFromCartRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    response_currency_code: value.response_currency_code,
    shopping_cart_hash: value.shopping_cart_hash,
    booking_hash: value.booking_hash
  };
}
