import { posApi } from '@/service/TicketingApi';
import { ref } from 'vue';
import { usePartner } from '../partner/use-partner';
import type { ProductInfo } from '@openapi/models';

const { locale } = usePartner();
const products = ref<ProductInfo[]>();
const productId = ref<string>();

export function useProducts() {
  const loading = ref(false);
  const error = ref();

  function reset() {
    productId.value = undefined;
  }

  function setProductId(id: string) {
    productId.value = id;
  }

  async function fetchProducts(productIds: string[]) {
    if (!locale.value) throw new Error('Locale not set');

    const productList = await posApi.posProductsGet({
      localeCode: locale.value,
      productIds
    });
    products.value = productList.products_info;
  }

  return {
    loading,
    error,
    setProductId,
    fetchProducts,
    reset,
    products,
    productId
  };
}
