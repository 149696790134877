/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConfirmRedirectRequest
 */
export interface ConfirmRedirectRequest {
  /**
   *
   * @type {string}
   * @memberof ConfirmRedirectRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmRedirectRequest
   */
  shopping_cart: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmRedirectRequest
   */
  redirectResult: string;
}

/**
 * Check if a given object implements the ConfirmRedirectRequest interface.
 */
export function instanceOfConfirmRedirectRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'shopping_cart' in value;
  isInstance = isInstance && 'redirectResult' in value;

  return isInstance;
}

export function ConfirmRedirectRequestFromJSON(json: any): ConfirmRedirectRequest {
  return ConfirmRedirectRequestFromJSONTyped(json, false);
}

export function ConfirmRedirectRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConfirmRedirectRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    shopping_cart: json['shopping_cart'],
    redirectResult: json['redirectResult']
  };
}

export function ConfirmRedirectRequestToJSON(value?: ConfirmRedirectRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    shopping_cart: value.shopping_cart,
    redirectResult: value.redirectResult
  };
}
