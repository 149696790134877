import { computed, ref } from 'vue';
import type { InitializationReadResponse1Partner } from '@openapi/models';
import { DEFAULT_CURRENCY } from '@/constants/currency';
import { FALLBACK_LOCALE } from '@/constants/i18n';

const partner = ref<InitializationReadResponse1Partner>();

export function usePartner() {
  const loading = ref(false);
  const error = ref();

  const currency = computed(() => partner.value?.currency.code || DEFAULT_CURRENCY);
  const locale = computed(() => partner.value?.locale.code || FALLBACK_LOCALE);

  function setPartner(newPartner: InitializationReadResponse1Partner) {
    partner.value = newPartner;
  }

  function reset() {
    partner.value = undefined;
  }

  return {
    loading,
    error,
    partner,
    reset,
    setPartner,
    currency,
    locale
  };
}
