/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AddToCartRequest,
  AddToCartResponse,
  AvailabilityReadResponse,
  BookingCancelRequest,
  BookingCancelResponse,
  BookingReadRequest,
  BookingReadResponse,
  CloneInPaymentCartRequest,
  CloneInPaymentCartResponse,
  ConfirmRedirectRequest,
  CreateCartRequest,
  CreateCartResponse,
  FinalizePaymentRequest,
  FinalizePaymentResponse,
  InitializationReadResponse1,
  InitiatePosPaymentRequest,
  InitiatePosPaymentResponse,
  NotificationsReadResponse,
  PricingReadResponse,
  ProductsReadResponse,
  RemoveFromCartRequest,
  RemoveFromCartResponse,
  ResetPasswordUpdateRequest,
  ResetPasswordUpdateResponse,
  RolesReadResponse,
  RolesUpdateRequest,
  RolesUpdateResponse,
  TicketsPrintRequest,
  TicketsPrintResponse,
  TranslationsReadResponse
} from '../models/index';
import {
  AddToCartRequestFromJSON,
  AddToCartRequestToJSON,
  AddToCartResponseFromJSON,
  AddToCartResponseToJSON,
  AvailabilityReadResponseFromJSON,
  AvailabilityReadResponseToJSON,
  BookingCancelRequestFromJSON,
  BookingCancelRequestToJSON,
  BookingCancelResponseFromJSON,
  BookingCancelResponseToJSON,
  BookingReadRequestFromJSON,
  BookingReadRequestToJSON,
  BookingReadResponseFromJSON,
  BookingReadResponseToJSON,
  CloneInPaymentCartRequestFromJSON,
  CloneInPaymentCartRequestToJSON,
  CloneInPaymentCartResponseFromJSON,
  CloneInPaymentCartResponseToJSON,
  ConfirmRedirectRequestFromJSON,
  ConfirmRedirectRequestToJSON,
  CreateCartRequestFromJSON,
  CreateCartRequestToJSON,
  CreateCartResponseFromJSON,
  CreateCartResponseToJSON,
  FinalizePaymentRequestFromJSON,
  FinalizePaymentRequestToJSON,
  FinalizePaymentResponseFromJSON,
  FinalizePaymentResponseToJSON,
  InitializationReadResponse1FromJSON,
  InitializationReadResponse1ToJSON,
  InitiatePosPaymentRequestFromJSON,
  InitiatePosPaymentRequestToJSON,
  InitiatePosPaymentResponseFromJSON,
  InitiatePosPaymentResponseToJSON,
  NotificationsReadResponseFromJSON,
  NotificationsReadResponseToJSON,
  PricingReadResponseFromJSON,
  PricingReadResponseToJSON,
  ProductsReadResponseFromJSON,
  ProductsReadResponseToJSON,
  RemoveFromCartRequestFromJSON,
  RemoveFromCartRequestToJSON,
  RemoveFromCartResponseFromJSON,
  RemoveFromCartResponseToJSON,
  ResetPasswordUpdateRequestFromJSON,
  ResetPasswordUpdateRequestToJSON,
  ResetPasswordUpdateResponseFromJSON,
  ResetPasswordUpdateResponseToJSON,
  RolesReadResponseFromJSON,
  RolesReadResponseToJSON,
  RolesUpdateRequestFromJSON,
  RolesUpdateRequestToJSON,
  RolesUpdateResponseFromJSON,
  RolesUpdateResponseToJSON,
  TicketsPrintRequestFromJSON,
  TicketsPrintRequestToJSON,
  TicketsPrintResponseFromJSON,
  TicketsPrintResponseToJSON,
  TranslationsReadResponseFromJSON,
  TranslationsReadResponseToJSON
} from '../models/index';

export interface PosAuthResetPasswordPostRequest {
  resetPasswordUpdateRequest?: ResetPasswordUpdateRequest;
}

export interface PosAuthUpdateRolePostRequest {
  rolesUpdateRequest?: RolesUpdateRequest;
}

export interface PosAvailabilityProductIdGetRequest {
  localeCode: string;
  productId: string;
  dateFrom: string;
  dateTo: string;
}

export interface PosBookingCancelBookingPostRequest {
  bookingCancelRequest?: BookingCancelRequest;
}

export interface PosBookingsPostRequest {
  bookingReadRequest?: BookingReadRequest;
}

export interface PosCartCloneInPaymentPostRequest {
  cloneInPaymentCartRequest?: CloneInPaymentCartRequest;
}

export interface PosCartCreatePostRequest {
  createCartRequest?: CreateCartRequest;
}

export interface PosCartItemsDeleteRequest {
  removeFromCartRequest?: RemoveFromCartRequest;
}

export interface PosCartItemsPostRequest {
  addToCartRequest?: AddToCartRequest;
}

export interface PosCheckoutConfirmRedirectGetRequest {
  confirmRedirectRequest?: ConfirmRedirectRequest;
}

export interface PosCheckoutFinalizePaymentPostRequest {
  finalizePaymentRequest?: FinalizePaymentRequest;
}

export interface PosCheckoutInitiatePaymentPostRequest {
  initiatePosPaymentRequest?: InitiatePosPaymentRequest;
}

export interface PosCheckoutNotificationsShoppingCartHashGetRequest {
  localeCode: string;
  shoppingCartHash: string;
  adyenServiceId?: string;
}

export interface PosCheckoutTicketsPrintPostRequest {
  ticketsPrintRequest?: TicketsPrintRequest;
}

export interface PosPricingGetRequest {
  localeCode: string;
  responseCurrencyCode: string;
  pricingId: string;
}

export interface PosProductsGetRequest {
  localeCode: string;
  productIds: Array<string>;
}

export interface PosTranslationsGetRequest {
  localeCode: string;
}

/**
 *
 */
export class PosApi extends runtime.BaseAPI {
  /**
   * Check role for GYG employees
   */
  async posAuthCheckRoleGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RolesReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/auth/check-role`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RolesReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Check role for GYG employees
   */
  async posAuthCheckRoleGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RolesReadResponse> {
    const response = await this.posAuthCheckRoleGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Reset password
   */
  async posAuthResetPasswordPostRaw(
    requestParameters: PosAuthResetPasswordPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResetPasswordUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/auth/reset-password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordUpdateRequestToJSON(requestParameters.resetPasswordUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResetPasswordUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Reset password
   */
  async posAuthResetPasswordPost(
    requestParameters: PosAuthResetPasswordPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResetPasswordUpdateResponse> {
    const response = await this.posAuthResetPasswordPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update role for GYG employees
   */
  async posAuthUpdateRolePostRaw(
    requestParameters: PosAuthUpdateRolePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RolesUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/auth/update-role`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RolesUpdateRequestToJSON(requestParameters.rolesUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RolesUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update role for GYG employees
   */
  async posAuthUpdateRolePost(
    requestParameters: PosAuthUpdateRolePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RolesUpdateResponse> {
    const response = await this.posAuthUpdateRolePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return availability for a product between a date range
   */
  async posAvailabilityProductIdGetRaw(
    requestParameters: PosAvailabilityProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AvailabilityReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling posAvailabilityProductIdGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling posAvailabilityProductIdGet.'
      );
    }

    if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
      throw new runtime.RequiredError(
        'dateFrom',
        'Required parameter requestParameters.dateFrom was null or undefined when calling posAvailabilityProductIdGet.'
      );
    }

    if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
      throw new runtime.RequiredError(
        'dateTo',
        'Required parameter requestParameters.dateTo was null or undefined when calling posAvailabilityProductIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.dateFrom !== undefined) {
      queryParameters['date_from'] = requestParameters.dateFrom;
    }

    if (requestParameters.dateTo !== undefined) {
      queryParameters['date_to'] = requestParameters.dateTo;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/availability/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AvailabilityReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Return availability for a product between a date range
   */
  async posAvailabilityProductIdGet(
    requestParameters: PosAvailabilityProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AvailabilityReadResponse> {
    const response = await this.posAvailabilityProductIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Cancel booking by a partner and refunds the amount dictated by the cancellation policy
   */
  async posBookingCancelBookingPostRaw(
    requestParameters: PosBookingCancelBookingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingCancelResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/booking/cancel-booking`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingCancelRequestToJSON(requestParameters.bookingCancelRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingCancelResponseFromJSON(jsonValue)
    );
  }

  /**
   * Cancel booking by a partner and refunds the amount dictated by the cancellation policy
   */
  async posBookingCancelBookingPost(
    requestParameters: PosBookingCancelBookingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingCancelResponse> {
    const response = await this.posBookingCancelBookingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get bookings for datatable
   */
  async posBookingsPostRaw(
    requestParameters: PosBookingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/bookings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingReadRequestToJSON(requestParameters.bookingReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get bookings for datatable
   */
  async posBookingsPost(
    requestParameters: PosBookingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingReadResponse> {
    const response = await this.posBookingsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Clone a shopping cart that has an \'in-payment\' status
   */
  async posCartCloneInPaymentPostRaw(
    requestParameters: PosCartCloneInPaymentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CloneInPaymentCartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/cart/clone-in-payment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CloneInPaymentCartRequestToJSON(requestParameters.cloneInPaymentCartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CloneInPaymentCartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Clone a shopping cart that has an \'in-payment\' status
   */
  async posCartCloneInPaymentPost(
    requestParameters: PosCartCloneInPaymentPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CloneInPaymentCartResponse> {
    const response = await this.posCartCloneInPaymentPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create a new shopping cart
   */
  async posCartCreatePostRaw(
    requestParameters: PosCartCreatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateCartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/cart/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCartRequestToJSON(requestParameters.createCartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateCartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a new shopping cart
   */
  async posCartCreatePost(
    requestParameters: PosCartCreatePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateCartResponse> {
    const response = await this.posCartCreatePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Remove items from the shopping cart
   */
  async posCartItemsDeleteRaw(
    requestParameters: PosCartItemsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RemoveFromCartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/cart/items`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveFromCartRequestToJSON(requestParameters.removeFromCartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RemoveFromCartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Remove items from the shopping cart
   */
  async posCartItemsDelete(
    requestParameters: PosCartItemsDeleteRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RemoveFromCartResponse> {
    const response = await this.posCartItemsDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Add items to the shopping cart
   */
  async posCartItemsPostRaw(
    requestParameters: PosCartItemsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddToCartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/cart/items`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddToCartRequestToJSON(requestParameters.addToCartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddToCartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Add items to the shopping cart
   */
  async posCartItemsPost(
    requestParameters: PosCartItemsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddToCartResponse> {
    const response = await this.posCartItemsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Confirm redirection from the 3DS flow and replies with redirect to the storefront
   */
  async posCheckoutConfirmRedirectGetRaw(
    requestParameters: PosCheckoutConfirmRedirectGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/checkout/confirm-redirect`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
        body: ConfirmRedirectRequestToJSON(requestParameters.confirmRedirectRequest)
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Confirm redirection from the 3DS flow and replies with redirect to the storefront
   */
  async posCheckoutConfirmRedirectGet(
    requestParameters: PosCheckoutConfirmRedirectGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.posCheckoutConfirmRedirectGetRaw(requestParameters, initOverrides);
  }

  /**
   * Finalize payment from the storefront
   */
  async posCheckoutFinalizePaymentPostRaw(
    requestParameters: PosCheckoutFinalizePaymentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FinalizePaymentResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/checkout/finalize-payment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FinalizePaymentRequestToJSON(requestParameters.finalizePaymentRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FinalizePaymentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Finalize payment from the storefront
   */
  async posCheckoutFinalizePaymentPost(
    requestParameters: PosCheckoutFinalizePaymentPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FinalizePaymentResponse> {
    const response = await this.posCheckoutFinalizePaymentPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Initiate payment from the storefront
   */
  async posCheckoutInitiatePaymentPostRaw(
    requestParameters: PosCheckoutInitiatePaymentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InitiatePosPaymentResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/checkout/initiate-payment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InitiatePosPaymentRequestToJSON(requestParameters.initiatePosPaymentRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InitiatePosPaymentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Initiate payment from the storefront
   */
  async posCheckoutInitiatePaymentPost(
    requestParameters: PosCheckoutInitiatePaymentPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InitiatePosPaymentResponse> {
    const response = await this.posCheckoutInitiatePaymentPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Notification event stream sent to the client
   */
  async posCheckoutNotificationsShoppingCartHashGetRaw(
    requestParameters: PosCheckoutNotificationsShoppingCartHashGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<NotificationsReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling posCheckoutNotificationsShoppingCartHashGet.'
      );
    }

    if (
      requestParameters.shoppingCartHash === null ||
      requestParameters.shoppingCartHash === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartHash',
        'Required parameter requestParameters.shoppingCartHash was null or undefined when calling posCheckoutNotificationsShoppingCartHashGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.adyenServiceId !== undefined) {
      queryParameters['adyen_service_id'] = requestParameters.adyenServiceId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/checkout/notifications/{shopping_cart_hash}`.replace(
          `{${'shopping_cart_hash'}}`,
          encodeURIComponent(String(requestParameters.shoppingCartHash))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NotificationsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Notification event stream sent to the client
   */
  async posCheckoutNotificationsShoppingCartHashGet(
    requestParameters: PosCheckoutNotificationsShoppingCartHashGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<NotificationsReadResponse> {
    const response = await this.posCheckoutNotificationsShoppingCartHashGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Print Tickets to customer
   */
  async posCheckoutTicketsPrintPostRaw(
    requestParameters: PosCheckoutTicketsPrintPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketsPrintResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/pos/checkout/tickets/print`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TicketsPrintRequestToJSON(requestParameters.ticketsPrintRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketsPrintResponseFromJSON(jsonValue)
    );
  }

  /**
   * Print Tickets to customer
   */
  async posCheckoutTicketsPrintPost(
    requestParameters: PosCheckoutTicketsPrintPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketsPrintResponse> {
    const response = await this.posCheckoutTicketsPrintPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Initialization endpoint to data for the POS
   */
  async posInitializationGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InitializationReadResponse1>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/initialization`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InitializationReadResponse1FromJSON(jsonValue)
    );
  }

  /**
   * Initialization endpoint to data for the POS
   */
  async posInitializationGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InitializationReadResponse1> {
    const response = await this.posInitializationGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Return price data for a given pricingId
   */
  async posPricingGetRaw(
    requestParameters: PosPricingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling posPricingGet.'
      );
    }

    if (
      requestParameters.responseCurrencyCode === null ||
      requestParameters.responseCurrencyCode === undefined
    ) {
      throw new runtime.RequiredError(
        'responseCurrencyCode',
        'Required parameter requestParameters.responseCurrencyCode was null or undefined when calling posPricingGet.'
      );
    }

    if (requestParameters.pricingId === null || requestParameters.pricingId === undefined) {
      throw new runtime.RequiredError(
        'pricingId',
        'Required parameter requestParameters.pricingId was null or undefined when calling posPricingGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.responseCurrencyCode !== undefined) {
      queryParameters['response_currency_code'] = requestParameters.responseCurrencyCode;
    }

    if (requestParameters.pricingId !== undefined) {
      queryParameters['pricing_id'] = requestParameters.pricingId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/pricing`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Return price data for a given pricingId
   */
  async posPricingGet(
    requestParameters: PosPricingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingReadResponse> {
    const response = await this.posPricingGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get information about one or multiple products
   */
  async posProductsGetRaw(
    requestParameters: PosProductsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling posProductsGet.'
      );
    }

    if (requestParameters.productIds === null || requestParameters.productIds === undefined) {
      throw new runtime.RequiredError(
        'productIds',
        'Required parameter requestParameters.productIds was null or undefined when calling posProductsGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.productIds) {
      queryParameters['product_ids[]'] = requestParameters.productIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/products`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get information about one or multiple products
   */
  async posProductsGet(
    requestParameters: PosProductsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsReadResponse> {
    const response = await this.posProductsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch translations for selected locale
   */
  async posTranslationsGetRaw(
    requestParameters: PosTranslationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TranslationsReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling posTranslationsGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pos/translations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TranslationsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch translations for selected locale
   */
  async posTranslationsGet(
    requestParameters: PosTranslationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TranslationsReadResponse> {
    const response = await this.posTranslationsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
