/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RolesReadResponsePartnersInner
 */
export interface RolesReadResponsePartnersInner {
  /**
   *
   * @type {string}
   * @memberof RolesReadResponsePartnersInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponsePartnersInner
   */
  name: string;
}

/**
 * Check if a given object implements the RolesReadResponsePartnersInner interface.
 */
export function instanceOfRolesReadResponsePartnersInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function RolesReadResponsePartnersInnerFromJSON(json: any): RolesReadResponsePartnersInner {
  return RolesReadResponsePartnersInnerFromJSONTyped(json, false);
}

export function RolesReadResponsePartnersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RolesReadResponsePartnersInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name']
  };
}

export function RolesReadResponsePartnersInnerToJSON(
  value?: RolesReadResponsePartnersInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name
  };
}
