/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingReadResponseAddonsInner } from './PricingReadResponseAddonsInner';
import {
  PricingReadResponseAddonsInnerFromJSON,
  PricingReadResponseAddonsInnerFromJSONTyped,
  PricingReadResponseAddonsInnerToJSON
} from './PricingReadResponseAddonsInner';
import type { PricingReadResponsePricingCategoriesInner } from './PricingReadResponsePricingCategoriesInner';
import {
  PricingReadResponsePricingCategoriesInnerFromJSON,
  PricingReadResponsePricingCategoriesInnerFromJSONTyped,
  PricingReadResponsePricingCategoriesInnerToJSON
} from './PricingReadResponsePricingCategoriesInner';

/**
 *
 * @export
 * @interface PricingReadResponse
 */
export interface PricingReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PricingReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponse
   */
  exception_message?: string;
  /**
   * Currency code selected in the client application. Prices in the response are returned in the selected response_currency_code
   * @type {string}
   * @memberof PricingReadResponse
   */
  response_currency_code: string;
  /**
   * the pricing categories for the given pricing ID in the order to be displayed
   * @type {Array<PricingReadResponsePricingCategoriesInner>}
   * @memberof PricingReadResponse
   */
  pricing_categories: Array<PricingReadResponsePricingCategoriesInner>;
  /**
   * The addons that can be added for the requested pricing_id
   * @type {Array<PricingReadResponseAddonsInner>}
   * @memberof PricingReadResponse
   */
  addons: Array<PricingReadResponseAddonsInner>;
}

/**
 * Check if a given object implements the PricingReadResponse interface.
 */
export function instanceOfPricingReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'response_currency_code' in value;
  isInstance = isInstance && 'pricing_categories' in value;
  isInstance = isInstance && 'addons' in value;

  return isInstance;
}

export function PricingReadResponseFromJSON(json: any): PricingReadResponse {
  return PricingReadResponseFromJSONTyped(json, false);
}

export function PricingReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    response_currency_code: json['response_currency_code'],
    pricing_categories: (json['pricing_categories'] as Array<any>).map(
      PricingReadResponsePricingCategoriesInnerFromJSON
    ),
    addons: (json['addons'] as Array<any>).map(PricingReadResponseAddonsInnerFromJSON)
  };
}

export function PricingReadResponseToJSON(value?: PricingReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    response_currency_code: value.response_currency_code,
    pricing_categories: (value.pricing_categories as Array<any>).map(
      PricingReadResponsePricingCategoriesInnerToJSON
    ),
    addons: (value.addons as Array<any>).map(PricingReadResponseAddonsInnerToJSON)
  };
}
