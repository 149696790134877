/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketsPrintRequest
 */
export interface TicketsPrintRequest {
  /**
   *
   * @type {string}
   * @memberof TicketsPrintRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof TicketsPrintRequest
   */
  poi_id: string;
  /**
   *
   * @type {string}
   * @memberof TicketsPrintRequest
   */
  shopping_cart_hash: string;
}

/**
 * Check if a given object implements the TicketsPrintRequest interface.
 */
export function instanceOfTicketsPrintRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'poi_id' in value;
  isInstance = isInstance && 'shopping_cart_hash' in value;

  return isInstance;
}

export function TicketsPrintRequestFromJSON(json: any): TicketsPrintRequest {
  return TicketsPrintRequestFromJSONTyped(json, false);
}

export function TicketsPrintRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketsPrintRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    poi_id: json['poi_id'],
    shopping_cart_hash: json['shopping_cart_hash']
  };
}

export function TicketsPrintRequestToJSON(value?: TicketsPrintRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    poi_id: value.poi_id,
    shopping_cart_hash: value.shopping_cart_hash
  };
}
