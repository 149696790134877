import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { alpha, alpha_spaces, required, max, min, confirmed } from '@vee-validate/rules';

// Import all the translations (TODO - can be optimized later by somehow dynamically importing the translations)
import da from '@vee-validate/i18n/dist/locale/da.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import fi from '@vee-validate/i18n/dist/locale/fi.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import ja from '@vee-validate/i18n/dist/locale/ja.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import no from '@vee-validate/i18n/dist/locale/nb_NO.json';
import pl from '@vee-validate/i18n/dist/locale/pl.json';
import sv from '@vee-validate/i18n/dist/locale/sv.json';
import ru from '@vee-validate/i18n/dist/locale/ru.json';

// Define rules globally, otherwise the error messages are not translated
defineRule('required', required);
defineRule('alpha_spaces', alpha_spaces);
defineRule('alpha', alpha);
defineRule('max', max);
defineRule('min', min);
defineRule('confirmed', confirmed);
defineRule('phone', (value: string) => {
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (!value || !phoneRegex.test(value)) {
    return false;
  }
  return true;
});
defineRule('verify_password', (value: string) => {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  return strongRegex.test(value);
});

export const configureVeeValidate = async () => {
  configure({
    generateMessage: localize({
      da,
      de,
      en,
      es,
      fi,
      fr,
      it,
      ja,
      nl,
      no,
      pl,
      sv,
      ru
    })
  });

  // Custom translations for translations
  configure({
    generateMessage: localize({
      da: {
        messages: {
          phone: 'Dette {field} skal være et internationalt telefonnummer'
        }
      },
      de: {
        messages: {
          phone: 'Dieses {field} sollte eine internationale Telefonnummer sein'
        }
      },
      en: {
        messages: {
          phone: 'This {field} should be an international phone number'
        }
      },
      es: {
        messages: {
          phone: 'Este {field} debe ser un número de teléfono internacional'
        }
      },
      fi: {
        messages: {
          phone: 'Tämän {field} tulee olla kansainvälinen puhelinnumero'
        }
      },
      fr: {
        messages: {
          phone: 'Ce {field} doit être un numéro de téléphone international'
        }
      },
      it: {
        messages: {
          phone: 'Questo {field} deve essere un numero di telefono internazionale'
        }
      },
      ja: {
        messages: {
          phone: 'この {field} は国際電話番号である必要があります'
        }
      },
      nl: {
        messages: {
          phone: 'Dit {field} moet een internationaal telefoonnummer zijn'
        }
      },
      no: {
        messages: {
          phone: 'Dette {field} skal være et internasjonalt telefonnummer'
        }
      },
      pl: {
        messages: {
          phone: 'To {field} powinno zawierać międzynarodowy numer telefonu'
        }
      },
      sv: {
        messages: {
          phone: 'Detta {field} ska vara ett internationellt telefonnummer'
        }
      },
      ru: {
        messages: {
          phone: 'Это {field} должно быть международным номером телефона.'
        }
      }
    })
  });
};
