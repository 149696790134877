/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingReadResponseAddonsInnerPrice
 */
export interface PricingReadResponseAddonsInnerPrice {
  /**
   * The price ID of the addon
   * @type {string}
   * @memberof PricingReadResponseAddonsInnerPrice
   */
  id: string;
  /**
   * The price value for one addon
   * @type {number}
   * @memberof PricingReadResponseAddonsInnerPrice
   */
  value: number;
}

/**
 * Check if a given object implements the PricingReadResponseAddonsInnerPrice interface.
 */
export function instanceOfPricingReadResponseAddonsInnerPrice(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'value' in value;

  return isInstance;
}

export function PricingReadResponseAddonsInnerPriceFromJSON(
  json: any
): PricingReadResponseAddonsInnerPrice {
  return PricingReadResponseAddonsInnerPriceFromJSONTyped(json, false);
}

export function PricingReadResponseAddonsInnerPriceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponseAddonsInnerPrice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    value: json['value']
  };
}

export function PricingReadResponseAddonsInnerPriceToJSON(
  value?: PricingReadResponseAddonsInnerPrice | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    value: value.value
  };
}
