/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InitializationReadResponse1PartnerCurrency } from './InitializationReadResponse1PartnerCurrency';
import {
  InitializationReadResponse1PartnerCurrencyFromJSON,
  InitializationReadResponse1PartnerCurrencyFromJSONTyped,
  InitializationReadResponse1PartnerCurrencyToJSON
} from './InitializationReadResponse1PartnerCurrency';
import type { InitializationReadResponse1PartnerLocale } from './InitializationReadResponse1PartnerLocale';
import {
  InitializationReadResponse1PartnerLocaleFromJSON,
  InitializationReadResponse1PartnerLocaleFromJSONTyped,
  InitializationReadResponse1PartnerLocaleToJSON
} from './InitializationReadResponse1PartnerLocale';

/**
 *
 * @export
 * @interface InitializationReadResponse1Partner
 */
export interface InitializationReadResponse1Partner {
  /**
   *
   * @type {InitializationReadResponse1PartnerLocale}
   * @memberof InitializationReadResponse1Partner
   */
  locale: InitializationReadResponse1PartnerLocale;
  /**
   *
   * @type {InitializationReadResponse1PartnerCurrency}
   * @memberof InitializationReadResponse1Partner
   */
  currency: InitializationReadResponse1PartnerCurrency;
}

/**
 * Check if a given object implements the InitializationReadResponse1Partner interface.
 */
export function instanceOfInitializationReadResponse1Partner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale' in value;
  isInstance = isInstance && 'currency' in value;

  return isInstance;
}

export function InitializationReadResponse1PartnerFromJSON(
  json: any
): InitializationReadResponse1Partner {
  return InitializationReadResponse1PartnerFromJSONTyped(json, false);
}

export function InitializationReadResponse1PartnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponse1Partner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale: InitializationReadResponse1PartnerLocaleFromJSON(json['locale']),
    currency: InitializationReadResponse1PartnerCurrencyFromJSON(json['currency'])
  };
}

export function InitializationReadResponse1PartnerToJSON(
  value?: InitializationReadResponse1Partner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale: InitializationReadResponse1PartnerLocaleToJSON(value.locale),
    currency: InitializationReadResponse1PartnerCurrencyToJSON(value.currency)
  };
}
