/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingReadResponseBookingsInnerParticipantsParticipantsInner } from './BookingReadResponseBookingsInnerParticipantsParticipantsInner';
import {
  BookingReadResponseBookingsInnerParticipantsParticipantsInnerFromJSON,
  BookingReadResponseBookingsInnerParticipantsParticipantsInnerFromJSONTyped,
  BookingReadResponseBookingsInnerParticipantsParticipantsInnerToJSON
} from './BookingReadResponseBookingsInnerParticipantsParticipantsInner';

/**
 *
 * @export
 * @interface BookingReadResponseBookingsInnerParticipants
 */
export interface BookingReadResponseBookingsInnerParticipants {
  /**
   *
   * @type {number}
   * @memberof BookingReadResponseBookingsInnerParticipants
   */
  total_participants: number;
  /**
   *
   * @type {Array<BookingReadResponseBookingsInnerParticipantsParticipantsInner>}
   * @memberof BookingReadResponseBookingsInnerParticipants
   */
  participants?: Array<BookingReadResponseBookingsInnerParticipantsParticipantsInner>;
}

/**
 * Check if a given object implements the BookingReadResponseBookingsInnerParticipants interface.
 */
export function instanceOfBookingReadResponseBookingsInnerParticipants(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_participants' in value;

  return isInstance;
}

export function BookingReadResponseBookingsInnerParticipantsFromJSON(
  json: any
): BookingReadResponseBookingsInnerParticipants {
  return BookingReadResponseBookingsInnerParticipantsFromJSONTyped(json, false);
}

export function BookingReadResponseBookingsInnerParticipantsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingReadResponseBookingsInnerParticipants {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_participants: json['total_participants'],
    participants: !exists(json, 'participants')
      ? undefined
      : (json['participants'] as Array<any>).map(
          BookingReadResponseBookingsInnerParticipantsParticipantsInnerFromJSON
        )
  };
}

export function BookingReadResponseBookingsInnerParticipantsToJSON(
  value?: BookingReadResponseBookingsInnerParticipants | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_participants: value.total_participants,
    participants:
      value.participants === undefined
        ? undefined
        : (value.participants as Array<any>).map(
            BookingReadResponseBookingsInnerParticipantsParticipantsInnerToJSON
          )
  };
}
