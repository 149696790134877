import { ref } from 'vue';
import type { Ref } from 'vue';
import { PosApi } from '@openapi/apis/PosApi';
import { Configuration } from '@openapi/runtime';
import { DIRECTUS_API_URL } from '@/config';
import { usePartner } from './partner/use-partner';

export default function useTranslate() {
  const loading: Ref<boolean> = ref(false);

  const fetchTranslationsByLocale = async () => {
    const config = new Configuration({
      basePath: DIRECTUS_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const posUnauthenticatedApi: PosApi = new PosApi(config);

    const { locale } = usePartner();
    loading.value = true;
    const requestParameters = {
      localeCode: locale.value
    };
    const response = await posUnauthenticatedApi.posTranslationsGet(requestParameters);
    if (!response.success) {
      throw new Error('Error while fetching the receipt settings');
    }
    loading.value = false;
    return response.translations;
  };

  return { loading, fetchTranslationsByLocale };
}
