<template>
  <div class="flex flex-column h-full flex-shrink-0 select-none">
    <div class="flex align-items-center justify-content-center flex-shrink-0">
      <GetYourGuideLogo class="p-3" />
    </div>
    <div class="overflow-y-auto">
      <ul class="list-none py-2 px-2 m-0">
        <li v-for="item in items" :key="item.label" class="mb-2">
          <router-link
            :to="{ name: item.name }"
            :class="`nav-menu__item p-2 no-underline flex align-items-center cursor-pointer lg:justify-content-center hover:surface-200 border-round text-secondary hover:text-primary transition-duration-150 transition-colors`"
          >
            <i :class="item.icon" class="mb-2 text-lg no-underline"></i>
            {{ item.label }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="mt-auto">
      <Avatar icon="pi pi-user" size="large" class="m-3" @click="toggle" />
      <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GetYourGuideLogo from './GetYourGuideLogo.vue';
import { ROUTE_NAMES } from '@/constants/routes';
import { computed, ref } from 'vue';
import Avatar from 'primevue/avatar';
import { useI18n } from 'vue-i18n';
import Menu from 'primevue/menu';
import { directus } from '@/service/directus';
import { useRouter } from 'vue-router';
const router = useRouter();
const { t } = useI18n();

const toggle = (event: Event) => {
  menu.value.toggle(event);
};
const menu = ref();
const menuItems = ref([
  {
    label: t('pos_nav_menu_title'),
    items: [
      {
        label: t('pos_nav_sign_out'),
        icon: 'pi pi-power-off',
        command: async () => {
          await directus.logout();
          router.push({ name: ROUTE_NAMES.LOGIN });
        }
      }
    ]
  }
]);

const items = computed(() => [
  {
    label: t('pos_nav_checkout'),
    icon: 'pi pi-th-large',
    name: ROUTE_NAMES.CHECKOUT
  },
  {
    label: t('pos_nav_bookings'),
    icon: 'pi pi-fw pi-book',
    name: ROUTE_NAMES.BOOKINGS
  },
  // {
  //   label: t('pos_nav_reports'),
  //   icon: 'pi pi-chart-line',
  //   name: ROUTE_NAMES.RE
  // },
  {
    label: t('pos_nav_settings'),
    icon: 'pi pi-cog',
    name: ROUTE_NAMES.SETTINGS
  }
]);
</script>

<style lang="scss" scoped>
.nav-menu {
  background-color: var(--surface-primary);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &__item {
    font-size: 0.65rem;
    color: var(--text-primary);
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.router-link-active {
      color: var(--primary-color);
      background-color: #f0f0f0;
    }
  }
}
</style>
