/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InitiatePosPaymentRequestCustomerInformation
 */
export interface InitiatePosPaymentRequestCustomerInformation {
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequestCustomerInformation
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequestCustomerInformation
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequestCustomerInformation
   */
  phone_nr?: string | null;
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequestCustomerInformation
   */
  locale: string;
}

/**
 * Check if a given object implements the InitiatePosPaymentRequestCustomerInformation interface.
 */
export function instanceOfInitiatePosPaymentRequestCustomerInformation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale' in value;

  return isInstance;
}

export function InitiatePosPaymentRequestCustomerInformationFromJSON(
  json: any
): InitiatePosPaymentRequestCustomerInformation {
  return InitiatePosPaymentRequestCustomerInformationFromJSONTyped(json, false);
}

export function InitiatePosPaymentRequestCustomerInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitiatePosPaymentRequestCustomerInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone_nr: !exists(json, 'phone_nr') ? undefined : json['phone_nr'],
    locale: json['locale']
  };
}

export function InitiatePosPaymentRequestCustomerInformationToJSON(
  value?: InitiatePosPaymentRequestCustomerInformation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    phone_nr: value.phone_nr,
    locale: value.locale
  };
}
