/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeData
 */
export interface GiftCodeData {
  /**
   *
   * @type {number}
   * @memberof GiftCodeData
   */
  price: number;
}

/**
 * Check if a given object implements the GiftCodeData interface.
 */
export function instanceOfGiftCodeData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'price' in value;

  return isInstance;
}

export function GiftCodeDataFromJSON(json: any): GiftCodeData {
  return GiftCodeDataFromJSONTyped(json, false);
}

export function GiftCodeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GiftCodeData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    price: json['price']
  };
}

export function GiftCodeDataToJSON(value?: GiftCodeData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    price: value.price
  };
}
