/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TranslationsReadResponse
 */
export interface TranslationsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof TranslationsReadResponse
   */
  success?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TranslationsReadResponse
   */
  translations: { [key: string]: string };
}

/**
 * Check if a given object implements the TranslationsReadResponse interface.
 */
export function instanceOfTranslationsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'translations' in value;

  return isInstance;
}

export function TranslationsReadResponseFromJSON(json: any): TranslationsReadResponse {
  return TranslationsReadResponseFromJSONTyped(json, false);
}

export function TranslationsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TranslationsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: !exists(json, 'success') ? undefined : json['success'],
    translations: json['translations']
  };
}

export function TranslationsReadResponseToJSON(value?: TranslationsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    translations: value.translations
  };
}
