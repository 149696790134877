/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingReadResponseAddonsInnerPrice } from './PricingReadResponseAddonsInnerPrice';
import {
  PricingReadResponseAddonsInnerPriceFromJSON,
  PricingReadResponseAddonsInnerPriceFromJSONTyped,
  PricingReadResponseAddonsInnerPriceToJSON
} from './PricingReadResponseAddonsInnerPrice';

/**
 *
 * @export
 * @interface PricingReadResponseAddonsInner
 */
export interface PricingReadResponseAddonsInner {
  /**
   * The addon id
   * @type {string}
   * @memberof PricingReadResponseAddonsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponseAddonsInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponseAddonsInner
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponseAddonsInner
   */
  added_to: PricingReadResponseAddonsInnerAddedToEnum;
  /**
   * The minimum quantity per 'added_to' unit of this addon. Example: 2 means that a minimum of 2 of this addon can be added for each participant or booking, respectively.
   * @type {number}
   * @memberof PricingReadResponseAddonsInner
   */
  min_quantity: number;
  /**
   * The maximum quantity per 'added_to' unit of this addon. Example: 2 means that a maximum of 2 of this addon can be added for each participant or booking, respectively.
   * @type {number}
   * @memberof PricingReadResponseAddonsInner
   */
  max_quantity: number | null;
  /**
   *
   * @type {PricingReadResponseAddonsInnerPrice}
   * @memberof PricingReadResponseAddonsInner
   */
  price: PricingReadResponseAddonsInnerPrice;
}

/**
 * @export
 */
export const PricingReadResponseAddonsInnerAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type PricingReadResponseAddonsInnerAddedToEnum =
  (typeof PricingReadResponseAddonsInnerAddedToEnum)[keyof typeof PricingReadResponseAddonsInnerAddedToEnum];

/**
 * Check if a given object implements the PricingReadResponseAddonsInner interface.
 */
export function instanceOfPricingReadResponseAddonsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'min_quantity' in value;
  isInstance = isInstance && 'max_quantity' in value;
  isInstance = isInstance && 'price' in value;

  return isInstance;
}

export function PricingReadResponseAddonsInnerFromJSON(json: any): PricingReadResponseAddonsInner {
  return PricingReadResponseAddonsInnerFromJSONTyped(json, false);
}

export function PricingReadResponseAddonsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponseAddonsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    added_to: json['added_to'],
    min_quantity: json['min_quantity'],
    max_quantity: json['max_quantity'],
    price: PricingReadResponseAddonsInnerPriceFromJSON(json['price'])
  };
}

export function PricingReadResponseAddonsInnerToJSON(
  value?: PricingReadResponseAddonsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    added_to: value.added_to,
    min_quantity: value.min_quantity,
    max_quantity: value.max_quantity,
    price: PricingReadResponseAddonsInnerPriceToJSON(value.price)
  };
}
