import { computed, ref } from 'vue';
import { formatCurrency } from '@/utils/format';
import { posApi } from '@/service/TicketingApi';
import type { AddToCartResponseShoppingCartData } from '@openapi/models';
import { usePartner } from '@/composables/partner/use-partner';

const shoppingCartHash = ref<string>();
const shoppingCartInfo = ref<AddToCartResponseShoppingCartData>();
const integrationId = ref<string>();
const { locale } = usePartner();

export function useShoppingCart() {
  const loading = ref(false);
  const error = ref();

  const total = computed(() =>
    formatCurrency(shoppingCartInfo.value?.total_price_partner_currency ?? 0)
  );
  const tax = computed(() => formatCurrency(shoppingCartInfo.value?.total_tax_amount ?? 0));
  const bookings = computed(() => shoppingCartInfo.value?.bookings_data ?? []);

  function setIntegrationId(id: string) {
    integrationId.value = id;
  }

  function setShoppingCartHash(hash: string) {
    shoppingCartHash.value = hash;
  }

  async function createShoppingCart() {
    if (!locale.value) {
      throw new Error('Locale not set');
    }
    if (!integrationId.value) {
      throw new Error('Integration ID not set');
    }

    const { hash_code } = await posApi.posCartCreatePost({
      createCartRequest: {
        locale_code: locale.value,
        integration_id: integrationId.value
      }
    });

    shoppingCartHash.value = hash_code;
  }

  function setShoppingCartInfo(updatedShoppingCartInfo: AddToCartResponseShoppingCartData) {
    shoppingCartInfo.value = updatedShoppingCartInfo;
  }

  function reset() {
    shoppingCartHash.value = undefined;
    shoppingCartInfo.value = undefined;
  }

  return {
    loading,
    error,
    setIntegrationId,
    createShoppingCart,
    setShoppingCartInfo,
    setShoppingCartHash,
    reset,
    shoppingCartHash,
    total,
    tax,
    bookings
  };
}
