/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddToCartRequestPartnerQuestionAnswersBookingInner } from './AddToCartRequestPartnerQuestionAnswersBookingInner';
import {
  AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSON,
  AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSONTyped,
  AddToCartRequestPartnerQuestionAnswersBookingInnerToJSON
} from './AddToCartRequestPartnerQuestionAnswersBookingInner';
import type { AddToCartRequestPartnerQuestionAnswersParticipantInner } from './AddToCartRequestPartnerQuestionAnswersParticipantInner';
import {
  AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSON,
  AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSONTyped,
  AddToCartRequestPartnerQuestionAnswersParticipantInnerToJSON
} from './AddToCartRequestPartnerQuestionAnswersParticipantInner';

/**
 *
 * @export
 * @interface AddToCartRequestPartnerQuestionAnswers
 */
export interface AddToCartRequestPartnerQuestionAnswers {
  /**
   *
   * @type {Array<AddToCartRequestPartnerQuestionAnswersBookingInner>}
   * @memberof AddToCartRequestPartnerQuestionAnswers
   */
  booking: Array<AddToCartRequestPartnerQuestionAnswersBookingInner>;
  /**
   *
   * @type {Array<AddToCartRequestPartnerQuestionAnswersParticipantInner>}
   * @memberof AddToCartRequestPartnerQuestionAnswers
   */
  participant: Array<AddToCartRequestPartnerQuestionAnswersParticipantInner>;
}

/**
 * Check if a given object implements the AddToCartRequestPartnerQuestionAnswers interface.
 */
export function instanceOfAddToCartRequestPartnerQuestionAnswers(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'booking' in value;
  isInstance = isInstance && 'participant' in value;

  return isInstance;
}

export function AddToCartRequestPartnerQuestionAnswersFromJSON(
  json: any
): AddToCartRequestPartnerQuestionAnswers {
  return AddToCartRequestPartnerQuestionAnswersFromJSONTyped(json, false);
}

export function AddToCartRequestPartnerQuestionAnswersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartRequestPartnerQuestionAnswers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    booking: (json['booking'] as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSON
    ),
    participant: (json['participant'] as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSON
    )
  };
}

export function AddToCartRequestPartnerQuestionAnswersToJSON(
  value?: AddToCartRequestPartnerQuestionAnswers | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    booking: (value.booking as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersBookingInnerToJSON
    ),
    participant: (value.participant as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersParticipantInnerToJSON
    )
  };
}
