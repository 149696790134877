import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import { isProduction, POS_RELEASE_VERSION } from '@/config';

export const useSentry = (app: App) => {
  if (isProduction) {
    Sentry.init({
      app,
      dsn: 'https://03e97152318f6d43ad87c63bcf200498@o551949.ingest.sentry.io/4506257215324160',
      environment: import.meta.env.MODE, // This translates to 'development', 'testing' or 'production'
      release: POS_RELEASE_VERSION,
      integrations: [
        new Sentry.InboundFilters({
          denyUrls: ['cdn.getyourguide.com', 'ticketing-pos.getyourguide.dev'],
          ignoreErrors: ['Response returned an error code']
        })
      ],
      logErrors: true
    });
  }
};
