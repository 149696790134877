/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingReadResponsePricingCategoriesInnerPricePricingScalesInner } from './PricingReadResponsePricingCategoriesInnerPricePricingScalesInner';
import {
  PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSON,
  PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSONTyped,
  PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerToJSON
} from './PricingReadResponsePricingCategoriesInnerPricePricingScalesInner';

/**
 *
 * @export
 * @interface PricingReadResponsePricingCategoriesInnerPrice
 */
export interface PricingReadResponsePricingCategoriesInnerPrice {
  /**
   * the price ID of this pricing category
   * @type {string}
   * @memberof PricingReadResponsePricingCategoriesInnerPrice
   */
  id: string;
  /**
   * the price value for one participant of this pricing category
   * @type {number}
   * @memberof PricingReadResponsePricingCategoriesInnerPrice
   */
  value: number;
  /**
   * the pricing scales (if any) for this price, sorted by from_num_participants in ascending order
   * @type {Array<PricingReadResponsePricingCategoriesInnerPricePricingScalesInner>}
   * @memberof PricingReadResponsePricingCategoriesInnerPrice
   */
  pricing_scales: Array<PricingReadResponsePricingCategoriesInnerPricePricingScalesInner>;
}

/**
 * Check if a given object implements the PricingReadResponsePricingCategoriesInnerPrice interface.
 */
export function instanceOfPricingReadResponsePricingCategoriesInnerPrice(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'value' in value;
  isInstance = isInstance && 'pricing_scales' in value;

  return isInstance;
}

export function PricingReadResponsePricingCategoriesInnerPriceFromJSON(
  json: any
): PricingReadResponsePricingCategoriesInnerPrice {
  return PricingReadResponsePricingCategoriesInnerPriceFromJSONTyped(json, false);
}

export function PricingReadResponsePricingCategoriesInnerPriceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponsePricingCategoriesInnerPrice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    value: json['value'],
    pricing_scales: (json['pricing_scales'] as Array<any>).map(
      PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSON
    )
  };
}

export function PricingReadResponsePricingCategoriesInnerPriceToJSON(
  value?: PricingReadResponsePricingCategoriesInnerPrice | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    value: value.value,
    pricing_scales: (value.pricing_scales as Array<any>).map(
      PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerToJSON
    )
  };
}
