import type { App } from 'vue';
import { useSentry } from '@/sentry';
import { configureVeeValidate } from '@/vee-validate';
import useTranslate from '@/composables/use-translate';
import { setPointOfSaleTranslations } from './i18n';
import { usePartner } from './composables/partner/use-partner';

/**
 * All the async calls necessary before starting the app should go here.
 */
export const bootstrapApp = async (app: App) => {
  // Initialize Sentry
  useSentry(app);

  const { fetchTranslationsByLocale } = useTranslate();
  const { locale } = usePartner();
  const translations = await fetchTranslationsByLocale();
  setPointOfSaleTranslations(locale.value, translations);

  // Translate error messages in vee-validate
  await configureVeeValidate();
};
