import type { ResponseContext } from '@openapi/runtime';
import { directus } from './directus';
import { Configuration } from '@openapi/runtime';
import { PosApi } from '@openapi/apis/PosApi';
import { DIRECTUS_API_URL } from '@/config';
import { LookupPartnerByUserExceptionCode } from '@/constants/exceptions';
import { isTokenExpiredError } from '@/utils/directus-error';
import router from '@/router/index';
import { ROUTE_NAMES } from '@/constants/routes';

const controller = new AbortController();
const { signal } = controller;

async function postMiddleware(responseContext: ResponseContext) {
  /**
   * If the token expires, we try and refresh it and redirect to login
   */
  const responseJson = await responseContext.response.json();
  if (isTokenExpiredError(responseJson)) {
    await directus.refresh();
    router.push({ name: ROUTE_NAMES.LOGIN });
  }

  /**
   * If an exception is thrown by the endpoint, logout the user
   * This is to cover the case when the user logs out in a different
   * tab but the current tab is still open and the auth_token still exists
   */
  if (
    responseJson.exception_code &&
    responseJson.exception_code === LookupPartnerByUserExceptionCode.PartnerNotFound
  ) {
    // This will throw a DOMException and all requests will be aborted
    controller.abort();
    await directus.logout();
    router.push({ name: ROUTE_NAMES.LOGIN });
  }
}

const preMiddleware = async (context: ResponseContext) => {
  // Add the abort signal to the context
  context.init.signal = signal;

  let directusAccessToken = await directus.getToken();
  if (!directusAccessToken) {
    try {
      const response = await directus.refresh();
      if (response) {
        directusAccessToken = response.access_token;
      }
    } catch (error) {
      router.push({ name: ROUTE_NAMES.LOGIN });
    }
  }

  context.init.headers = {
    ...context.init.headers,
    Authorization: `Bearer ${directusAccessToken}`
  };
  return context;
};

const config = new Configuration({
  basePath: DIRECTUS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  middleware: [
    {
      pre: preMiddleware,
      post: postMiddleware
    }
  ]
});

const posApi = new PosApi(config);
export { posApi };
