/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvailabilityReadResponseAvailabilitiesByDateValueInner } from './AvailabilityReadResponseAvailabilitiesByDateValueInner';
import {
  AvailabilityReadResponseAvailabilitiesByDateValueInnerFromJSON,
  AvailabilityReadResponseAvailabilitiesByDateValueInnerFromJSONTyped,
  AvailabilityReadResponseAvailabilitiesByDateValueInnerToJSON
} from './AvailabilityReadResponseAvailabilitiesByDateValueInner';

/**
 *
 * @export
 * @interface AvailabilityReadResponse
 */
export interface AvailabilityReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof AvailabilityReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof AvailabilityReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AvailabilityReadResponse
   */
  disabled_dates: Array<string>;
  /**
   *
   * @type {{ [key: string]: Array<AvailabilityReadResponseAvailabilitiesByDateValueInner>; }}
   * @memberof AvailabilityReadResponse
   */
  availabilities_by_date: {
    [key: string]: Array<AvailabilityReadResponseAvailabilitiesByDateValueInner>;
  };
}

/**
 * Check if a given object implements the AvailabilityReadResponse interface.
 */
export function instanceOfAvailabilityReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'disabled_dates' in value;
  isInstance = isInstance && 'availabilities_by_date' in value;

  return isInstance;
}

export function AvailabilityReadResponseFromJSON(json: any): AvailabilityReadResponse {
  return AvailabilityReadResponseFromJSONTyped(json, false);
}

export function AvailabilityReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AvailabilityReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    disabled_dates: json['disabled_dates'],
    availabilities_by_date: json['availabilities_by_date']
  };
}

export function AvailabilityReadResponseToJSON(value?: AvailabilityReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    disabled_dates: value.disabled_dates,
    availabilities_by_date: value.availabilities_by_date
  };
}
