/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InitializationReadResponse1Partner } from './InitializationReadResponse1Partner';
import {
  InitializationReadResponse1PartnerFromJSON,
  InitializationReadResponse1PartnerFromJSONTyped,
  InitializationReadResponse1PartnerToJSON
} from './InitializationReadResponse1Partner';
import type { InitializationReadResponse1TerminalsInner } from './InitializationReadResponse1TerminalsInner';
import {
  InitializationReadResponse1TerminalsInnerFromJSON,
  InitializationReadResponse1TerminalsInnerFromJSONTyped,
  InitializationReadResponse1TerminalsInnerToJSON
} from './InitializationReadResponse1TerminalsInner';

/**
 *
 * @export
 * @interface InitializationReadResponse1
 */
export interface InitializationReadResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponse1
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse1
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse1
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse1
   */
  integration_id: string;
  /**
   *
   * @type {InitializationReadResponse1Partner}
   * @memberof InitializationReadResponse1
   */
  partner: InitializationReadResponse1Partner;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof InitializationReadResponse1
   */
  translations: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof InitializationReadResponse1
   */
  products: Array<string>;
  /**
   *
   * @type {Array<InitializationReadResponse1TerminalsInner>}
   * @memberof InitializationReadResponse1
   */
  terminals: Array<InitializationReadResponse1TerminalsInner>;
}

/**
 * Check if a given object implements the InitializationReadResponse1 interface.
 */
export function instanceOfInitializationReadResponse1(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'integration_id' in value;
  isInstance = isInstance && 'partner' in value;
  isInstance = isInstance && 'translations' in value;
  isInstance = isInstance && 'products' in value;
  isInstance = isInstance && 'terminals' in value;

  return isInstance;
}

export function InitializationReadResponse1FromJSON(json: any): InitializationReadResponse1 {
  return InitializationReadResponse1FromJSONTyped(json, false);
}

export function InitializationReadResponse1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponse1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    integration_id: json['integration_id'],
    partner: InitializationReadResponse1PartnerFromJSON(json['partner']),
    translations: json['translations'],
    products: json['products'],
    terminals: (json['terminals'] as Array<any>).map(
      InitializationReadResponse1TerminalsInnerFromJSON
    )
  };
}

export function InitializationReadResponse1ToJSON(value?: InitializationReadResponse1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    integration_id: value.integration_id,
    partner: InitializationReadResponse1PartnerToJSON(value.partner),
    translations: value.translations,
    products: value.products,
    terminals: (value.terminals as Array<any>).map(InitializationReadResponse1TerminalsInnerToJSON)
  };
}
