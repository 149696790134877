/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationsReadResponseNotification } from './NotificationsReadResponseNotification';
import {
  NotificationsReadResponseNotificationFromJSON,
  NotificationsReadResponseNotificationFromJSONTyped,
  NotificationsReadResponseNotificationToJSON
} from './NotificationsReadResponseNotification';

/**
 *
 * @export
 * @interface NotificationsReadResponse
 */
export interface NotificationsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof NotificationsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {NotificationsReadResponseNotification}
   * @memberof NotificationsReadResponse
   */
  notification: NotificationsReadResponseNotification;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsReadResponse
   */
  retry: boolean;
}

/**
 * Check if a given object implements the NotificationsReadResponse interface.
 */
export function instanceOfNotificationsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'notification' in value;
  isInstance = isInstance && 'retry' in value;

  return isInstance;
}

export function NotificationsReadResponseFromJSON(json: any): NotificationsReadResponse {
  return NotificationsReadResponseFromJSONTyped(json, false);
}

export function NotificationsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NotificationsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    notification: NotificationsReadResponseNotificationFromJSON(json['notification']),
    retry: json['retry']
  };
}

export function NotificationsReadResponseToJSON(value?: NotificationsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    notification: NotificationsReadResponseNotificationToJSON(value.notification),
    retry: value.retry
  };
}
