export function isTokenExpiredError(err: any): boolean {
  if (!Object.hasOwnProperty.call(err, 'errors')) {
    return false;
  } else {
    const error = err.errors[0];
    return (
      error.extensions &&
      error.extensions.code === 'TOKEN_EXPIRED' &&
      error.message === 'Token expired.'
    );
  }
}
