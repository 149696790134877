/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddToCartResponseShoppingCartDataAppliedGiftCodesInner } from './AddToCartResponseShoppingCartDataAppliedGiftCodesInner';
import {
  AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSON,
  AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSONTyped,
  AddToCartResponseShoppingCartDataAppliedGiftCodesInnerToJSON
} from './AddToCartResponseShoppingCartDataAppliedGiftCodesInner';
import type { BookingData } from './BookingData';
import { BookingDataFromJSON, BookingDataFromJSONTyped, BookingDataToJSON } from './BookingData';
import type { GiftCodeData } from './GiftCodeData';
import {
  GiftCodeDataFromJSON,
  GiftCodeDataFromJSONTyped,
  GiftCodeDataToJSON
} from './GiftCodeData';

/**
 *
 * @export
 * @interface AddToCartResponseShoppingCartData
 */
export interface AddToCartResponseShoppingCartData {
  /**
   *
   * @type {Array<string>}
   * @memberof AddToCartResponseShoppingCartData
   */
  payment_modes?: Array<AddToCartResponseShoppingCartDataPaymentModesEnum>;
  /**
   *
   * @type {string}
   * @memberof AddToCartResponseShoppingCartData
   */
  expires_at: string | null;
  /**
   *
   * @type {string}
   * @memberof AddToCartResponseShoppingCartData
   */
  hash_code: string;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartData
   */
  total_price_partner_currency: number;
  /**
   * Partner currency code
   * @type {string}
   * @memberof AddToCartResponseShoppingCartData
   */
  partner_currency_code: string;
  /**
   * Currency code selected in the client application. Prices in the response are in the selected response_currency_code
   * @type {string}
   * @memberof AddToCartResponseShoppingCartData
   */
  response_currency_code: string;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartData
   */
  total_price: number;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartData
   */
  total_price_pre_promo: number;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartData
   */
  total_tax_amount: number;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartData
   */
  amount_to_pay: number;
  /**
   *
   * @type {Array<BookingData>}
   * @memberof AddToCartResponseShoppingCartData
   */
  bookings_data?: Array<BookingData>;
  /**
   *
   * @type {Array<GiftCodeData>}
   * @memberof AddToCartResponseShoppingCartData
   */
  gift_codes_data?: Array<GiftCodeData>;
  /**
   *
   * @type {Array<AddToCartResponseShoppingCartDataAppliedGiftCodesInner>}
   * @memberof AddToCartResponseShoppingCartData
   */
  applied_gift_codes?: Array<AddToCartResponseShoppingCartDataAppliedGiftCodesInner>;
}

/**
 * @export
 */
export const AddToCartResponseShoppingCartDataPaymentModesEnum = {
  Adyen: 'adyen',
  PayOnsite: 'pay_onsite'
} as const;
export type AddToCartResponseShoppingCartDataPaymentModesEnum =
  (typeof AddToCartResponseShoppingCartDataPaymentModesEnum)[keyof typeof AddToCartResponseShoppingCartDataPaymentModesEnum];

/**
 * Check if a given object implements the AddToCartResponseShoppingCartData interface.
 */
export function instanceOfAddToCartResponseShoppingCartData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'expires_at' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'total_price_partner_currency' in value;
  isInstance = isInstance && 'partner_currency_code' in value;
  isInstance = isInstance && 'response_currency_code' in value;
  isInstance = isInstance && 'total_price' in value;
  isInstance = isInstance && 'total_price_pre_promo' in value;
  isInstance = isInstance && 'total_tax_amount' in value;
  isInstance = isInstance && 'amount_to_pay' in value;

  return isInstance;
}

export function AddToCartResponseShoppingCartDataFromJSON(
  json: any
): AddToCartResponseShoppingCartData {
  return AddToCartResponseShoppingCartDataFromJSONTyped(json, false);
}

export function AddToCartResponseShoppingCartDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartResponseShoppingCartData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    payment_modes: !exists(json, 'payment_modes') ? undefined : json['payment_modes'],
    expires_at: json['expires_at'],
    hash_code: json['hash_code'],
    total_price_partner_currency: json['total_price_partner_currency'],
    partner_currency_code: json['partner_currency_code'],
    response_currency_code: json['response_currency_code'],
    total_price: json['total_price'],
    total_price_pre_promo: json['total_price_pre_promo'],
    total_tax_amount: json['total_tax_amount'],
    amount_to_pay: json['amount_to_pay'],
    bookings_data: !exists(json, 'bookings_data')
      ? undefined
      : (json['bookings_data'] as Array<any>).map(BookingDataFromJSON),
    gift_codes_data: !exists(json, 'gift_codes_data')
      ? undefined
      : (json['gift_codes_data'] as Array<any>).map(GiftCodeDataFromJSON),
    applied_gift_codes: !exists(json, 'applied_gift_codes')
      ? undefined
      : (json['applied_gift_codes'] as Array<any>).map(
          AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSON
        )
  };
}

export function AddToCartResponseShoppingCartDataToJSON(
  value?: AddToCartResponseShoppingCartData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_modes: value.payment_modes,
    expires_at: value.expires_at,
    hash_code: value.hash_code,
    total_price_partner_currency: value.total_price_partner_currency,
    partner_currency_code: value.partner_currency_code,
    response_currency_code: value.response_currency_code,
    total_price: value.total_price,
    total_price_pre_promo: value.total_price_pre_promo,
    total_tax_amount: value.total_tax_amount,
    amount_to_pay: value.amount_to_pay,
    bookings_data:
      value.bookings_data === undefined
        ? undefined
        : (value.bookings_data as Array<any>).map(BookingDataToJSON),
    gift_codes_data:
      value.gift_codes_data === undefined
        ? undefined
        : (value.gift_codes_data as Array<any>).map(GiftCodeDataToJSON),
    applied_gift_codes:
      value.applied_gift_codes === undefined
        ? undefined
        : (value.applied_gift_codes as Array<any>).map(
            AddToCartResponseShoppingCartDataAppliedGiftCodesInnerToJSON
          )
  };
}
