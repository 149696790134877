/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductInfoProductMediaInner
 */
export interface ProductInfoProductMediaInner {
  /**
   *
   * @type {string}
   * @memberof ProductInfoProductMediaInner
   */
  directus_file_id: string;
}

/**
 * Check if a given object implements the ProductInfoProductMediaInner interface.
 */
export function instanceOfProductInfoProductMediaInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'directus_file_id' in value;

  return isInstance;
}

export function ProductInfoProductMediaInnerFromJSON(json: any): ProductInfoProductMediaInner {
  return ProductInfoProductMediaInnerFromJSONTyped(json, false);
}

export function ProductInfoProductMediaInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductInfoProductMediaInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    directus_file_id: json['directus_file_id']
  };
}

export function ProductInfoProductMediaInnerToJSON(
  value?: ProductInfoProductMediaInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    directus_file_id: value.directus_file_id
  };
}
