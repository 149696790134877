/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner } from './ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner';
import {
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerFromJSON,
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerFromJSONTyped,
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerToJSON
} from './ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner';

/**
 *
 * @export
 * @interface ProductInfoProductOptionsWithTranslationsValuePartnerQuestions
 */
export interface ProductInfoProductOptionsWithTranslationsValuePartnerQuestions {
  /**
   *
   * @type {Array<ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner>}
   * @memberof ProductInfoProductOptionsWithTranslationsValuePartnerQuestions
   */
  booking: Array<ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner>;
  /**
   *
   * @type {Array<ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner>}
   * @memberof ProductInfoProductOptionsWithTranslationsValuePartnerQuestions
   */
  participant: Array<ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInner>;
}

/**
 * Check if a given object implements the ProductInfoProductOptionsWithTranslationsValuePartnerQuestions interface.
 */
export function instanceOfProductInfoProductOptionsWithTranslationsValuePartnerQuestions(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'booking' in value;
  isInstance = isInstance && 'participant' in value;

  return isInstance;
}

export function ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSON(
  json: any
): ProductInfoProductOptionsWithTranslationsValuePartnerQuestions {
  return ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSONTyped(json, false);
}

export function ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductInfoProductOptionsWithTranslationsValuePartnerQuestions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    booking: (json['booking'] as Array<any>).map(
      ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerFromJSON
    ),
    participant: (json['participant'] as Array<any>).map(
      ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerFromJSON
    )
  };
}

export function ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsToJSON(
  value?: ProductInfoProductOptionsWithTranslationsValuePartnerQuestions | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    booking: (value.booking as Array<any>).map(
      ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerToJSON
    ),
    participant: (value.participant as Array<any>).map(
      ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsBookingInnerToJSON
    )
  };
}
