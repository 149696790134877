/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingReadResponsePricingCategoriesInnerPrice } from './PricingReadResponsePricingCategoriesInnerPrice';
import {
  PricingReadResponsePricingCategoriesInnerPriceFromJSON,
  PricingReadResponsePricingCategoriesInnerPriceFromJSONTyped,
  PricingReadResponsePricingCategoriesInnerPriceToJSON
} from './PricingReadResponsePricingCategoriesInnerPrice';

/**
 *
 * @export
 * @interface PricingReadResponsePricingCategoriesInner
 */
export interface PricingReadResponsePricingCategoriesInner {
  /**
   * the pricing-category ID
   * @type {string}
   * @memberof PricingReadResponsePricingCategoriesInner
   */
  id: string;
  /**
   * minimum age in years for this category
   * @type {number}
   * @memberof PricingReadResponsePricingCategoriesInner
   */
  min_age: number | null;
  /**
   * maximum age in years for this category
   * @type {number}
   * @memberof PricingReadResponsePricingCategoriesInner
   */
  max_age: number | null;
  /**
   * the name of this category in the given locale
   * @type {string}
   * @memberof PricingReadResponsePricingCategoriesInner
   */
  name: string;
  /**
   *
   * @type {PricingReadResponsePricingCategoriesInnerPrice}
   * @memberof PricingReadResponsePricingCategoriesInner
   */
  price: PricingReadResponsePricingCategoriesInnerPrice;
}

/**
 * Check if a given object implements the PricingReadResponsePricingCategoriesInner interface.
 */
export function instanceOfPricingReadResponsePricingCategoriesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'min_age' in value;
  isInstance = isInstance && 'max_age' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'price' in value;

  return isInstance;
}

export function PricingReadResponsePricingCategoriesInnerFromJSON(
  json: any
): PricingReadResponsePricingCategoriesInner {
  return PricingReadResponsePricingCategoriesInnerFromJSONTyped(json, false);
}

export function PricingReadResponsePricingCategoriesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponsePricingCategoriesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    min_age: json['min_age'],
    max_age: json['max_age'],
    name: json['name'],
    price: PricingReadResponsePricingCategoriesInnerPriceFromJSON(json['price'])
  };
}

export function PricingReadResponsePricingCategoriesInnerToJSON(
  value?: PricingReadResponsePricingCategoriesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    min_age: value.min_age,
    max_age: value.max_age,
    name: value.name,
    price: PricingReadResponsePricingCategoriesInnerPriceToJSON(value.price)
  };
}
