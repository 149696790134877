/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingReadResponseBookingsInnerParticipants } from './BookingReadResponseBookingsInnerParticipants';
import {
  BookingReadResponseBookingsInnerParticipantsFromJSON,
  BookingReadResponseBookingsInnerParticipantsFromJSONTyped,
  BookingReadResponseBookingsInnerParticipantsToJSON
} from './BookingReadResponseBookingsInnerParticipants';

/**
 *
 * @export
 * @interface BookingReadResponseBookingsInner
 */
export interface BookingReadResponseBookingsInner {
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  shopping_cart_hash: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  status: BookingReadResponseBookingsInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  revenue: string;
  /**
   *
   * @type {BookingReadResponseBookingsInnerParticipants}
   * @memberof BookingReadResponseBookingsInner
   */
  participants: BookingReadResponseBookingsInnerParticipants;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  purchase_date: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  travel_date: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponseBookingsInner
   */
  channel: string;
}

/**
 * @export
 */
export const BookingReadResponseBookingsInnerStatusEnum = {
  Active: 'active',
  CancelledByCustomer: 'cancelled_by_customer',
  CancelledByPartner: 'cancelled_by_partner',
  DeletedByCustomer: 'deleted_by_customer',
  Processed: 'processed',
  CancelledByAdmin: 'cancelled_by_admin',
  DeletedByCleanupJob: 'deleted_by_cleanup_job',
  DeletedForCloning: 'deleted_for_cloning',
  ReservationSystemUnconfirmed: 'reservation_system_unconfirmed',
  AwaitingPartnerConfirmation: 'awaiting_partner_confirmation',
  PendingPaymentCompletion: 'pending_payment_completion'
} as const;
export type BookingReadResponseBookingsInnerStatusEnum =
  (typeof BookingReadResponseBookingsInnerStatusEnum)[keyof typeof BookingReadResponseBookingsInnerStatusEnum];

/**
 * Check if a given object implements the BookingReadResponseBookingsInner interface.
 */
export function instanceOfBookingReadResponseBookingsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'shopping_cart_hash' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'revenue' in value;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'purchase_date' in value;
  isInstance = isInstance && 'travel_date' in value;
  isInstance = isInstance && 'channel' in value;

  return isInstance;
}

export function BookingReadResponseBookingsInnerFromJSON(
  json: any
): BookingReadResponseBookingsInner {
  return BookingReadResponseBookingsInnerFromJSONTyped(json, false);
}

export function BookingReadResponseBookingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingReadResponseBookingsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    hash_code: json['hash_code'],
    shopping_cart_hash: json['shopping_cart_hash'],
    status: json['status'],
    revenue: json['revenue'],
    participants: BookingReadResponseBookingsInnerParticipantsFromJSON(json['participants']),
    purchase_date: json['purchase_date'],
    travel_date: json['travel_date'],
    channel: json['channel']
  };
}

export function BookingReadResponseBookingsInnerToJSON(
  value?: BookingReadResponseBookingsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    hash_code: value.hash_code,
    shopping_cart_hash: value.shopping_cart_hash,
    status: value.status,
    revenue: value.revenue,
    participants: BookingReadResponseBookingsInnerParticipantsToJSON(value.participants),
    purchase_date: value.purchase_date,
    travel_date: value.travel_date,
    channel: value.channel
  };
}
