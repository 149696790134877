import { createI18n } from 'vue-i18n';
import { DEFAULT_LOCALE, FALLBACK_LOCALE } from '@/constants/i18n';

export const setPointOfSaleTranslations = async (
  locale: string,
  translations: { [key: string]: string }
) => {
  i18n.global.setLocaleMessage(locale, translations);
  i18n.global.locale.value = locale;
};

const i18n = createI18n({
  fallbackLocale: FALLBACK_LOCALE,
  locale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  silentFallbackWarn: true
});

export default i18n;
