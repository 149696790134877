/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InitiatePosPaymentRequestCustomerInformation } from './InitiatePosPaymentRequestCustomerInformation';
import {
  InitiatePosPaymentRequestCustomerInformationFromJSON,
  InitiatePosPaymentRequestCustomerInformationFromJSONTyped,
  InitiatePosPaymentRequestCustomerInformationToJSON
} from './InitiatePosPaymentRequestCustomerInformation';

/**
 *
 * @export
 * @interface InitiatePosPaymentRequest
 */
export interface InitiatePosPaymentRequest {
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequest
   */
  shopping_cart_hash: string;
  /**
   *
   * @type {InitiatePosPaymentRequestCustomerInformation}
   * @memberof InitiatePosPaymentRequest
   */
  customer_information: InitiatePosPaymentRequestCustomerInformation;
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequest
   */
  poi_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof InitiatePosPaymentRequest
   */
  payment_method_type: InitiatePosPaymentRequestPaymentMethodTypeEnum;
}

/**
 * @export
 */
export const InitiatePosPaymentRequestPaymentMethodTypeEnum = {
  AdyenWeb: 'adyen_web',
  AdyenPos: 'adyen_pos',
  Cash: 'cash',
  GiftCode: 'gift_code',
  Other: 'other',
  ExternalDevice: 'external_device'
} as const;
export type InitiatePosPaymentRequestPaymentMethodTypeEnum =
  (typeof InitiatePosPaymentRequestPaymentMethodTypeEnum)[keyof typeof InitiatePosPaymentRequestPaymentMethodTypeEnum];

/**
 * Check if a given object implements the InitiatePosPaymentRequest interface.
 */
export function instanceOfInitiatePosPaymentRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'shopping_cart_hash' in value;
  isInstance = isInstance && 'customer_information' in value;
  isInstance = isInstance && 'payment_method_type' in value;

  return isInstance;
}

export function InitiatePosPaymentRequestFromJSON(json: any): InitiatePosPaymentRequest {
  return InitiatePosPaymentRequestFromJSONTyped(json, false);
}

export function InitiatePosPaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitiatePosPaymentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shopping_cart_hash: json['shopping_cart_hash'],
    customer_information: InitiatePosPaymentRequestCustomerInformationFromJSON(
      json['customer_information']
    ),
    poi_id: !exists(json, 'poi_id') ? undefined : json['poi_id'],
    payment_method_type: json['payment_method_type']
  };
}

export function InitiatePosPaymentRequestToJSON(value?: InitiatePosPaymentRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shopping_cart_hash: value.shopping_cart_hash,
    customer_information: InitiatePosPaymentRequestCustomerInformationToJSON(
      value.customer_information
    ),
    poi_id: value.poi_id,
    payment_method_type: value.payment_method_type
  };
}
