export const ROUTE_NAMES = {
  HOME: 'home',
  CHECKOUT: 'checkout',
  BOOKINGS: 'bookings',
  SETTINGS: 'settings',
  NOT_FOUND: 'not-found',
  LOGIN_GOOGLE: 'login-google',
  LOGIN_IMPERSONATE: 'login-impersonate',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  UPDATE_PASSWORD: 'update-password'
};
