/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddToCartResponseShoppingCartDataAppliedGiftCodesInner
 */
export interface AddToCartResponseShoppingCartDataAppliedGiftCodesInner {
  /**
   *
   * @type {string}
   * @memberof AddToCartResponseShoppingCartDataAppliedGiftCodesInner
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof AddToCartResponseShoppingCartDataAppliedGiftCodesInner
   */
  amount_used: number;
}

/**
 * Check if a given object implements the AddToCartResponseShoppingCartDataAppliedGiftCodesInner interface.
 */
export function instanceOfAddToCartResponseShoppingCartDataAppliedGiftCodesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'amount_used' in value;

  return isInstance;
}

export function AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSON(
  json: any
): AddToCartResponseShoppingCartDataAppliedGiftCodesInner {
  return AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSONTyped(json, false);
}

export function AddToCartResponseShoppingCartDataAppliedGiftCodesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartResponseShoppingCartDataAppliedGiftCodesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    amount_used: json['amount_used']
  };
}

export function AddToCartResponseShoppingCartDataAppliedGiftCodesInnerToJSON(
  value?: AddToCartResponseShoppingCartDataAppliedGiftCodesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    amount_used: value.amount_used
  };
}
