/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingReadResponseBookingsInner } from './BookingReadResponseBookingsInner';
import {
  BookingReadResponseBookingsInnerFromJSON,
  BookingReadResponseBookingsInnerFromJSONTyped,
  BookingReadResponseBookingsInnerToJSON
} from './BookingReadResponseBookingsInner';
import type { BookingReadResponseMeta } from './BookingReadResponseMeta';
import {
  BookingReadResponseMetaFromJSON,
  BookingReadResponseMetaFromJSONTyped,
  BookingReadResponseMetaToJSON
} from './BookingReadResponseMeta';

/**
 *
 * @export
 * @interface BookingReadResponse
 */
export interface BookingReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof BookingReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof BookingReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {BookingReadResponseMeta}
   * @memberof BookingReadResponse
   */
  meta: BookingReadResponseMeta;
  /**
   *
   * @type {Array<BookingReadResponseBookingsInner>}
   * @memberof BookingReadResponse
   */
  bookings: Array<BookingReadResponseBookingsInner>;
}

/**
 * Check if a given object implements the BookingReadResponse interface.
 */
export function instanceOfBookingReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'bookings' in value;

  return isInstance;
}

export function BookingReadResponseFromJSON(json: any): BookingReadResponse {
  return BookingReadResponseFromJSONTyped(json, false);
}

export function BookingReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: BookingReadResponseMetaFromJSON(json['meta']),
    bookings: (json['bookings'] as Array<any>).map(BookingReadResponseBookingsInnerFromJSON)
  };
}

export function BookingReadResponseToJSON(value?: BookingReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: BookingReadResponseMetaToJSON(value.meta),
    bookings: (value.bookings as Array<any>).map(BookingReadResponseBookingsInnerToJSON)
  };
}
