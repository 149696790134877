/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingDataProductData
 */
export interface BookingDataProductData {
  /**
   *
   * @type {string}
   * @memberof BookingDataProductData
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BookingDataProductData
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingDataProductData
   */
  title?: string;
}

/**
 * Check if a given object implements the BookingDataProductData interface.
 */
export function instanceOfBookingDataProductData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function BookingDataProductDataFromJSON(json: any): BookingDataProductData {
  return BookingDataProductDataFromJSONTyped(json, false);
}

export function BookingDataProductDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingDataProductData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    title: !exists(json, 'title') ? undefined : json['title']
  };
}

export function BookingDataProductDataToJSON(value?: BookingDataProductData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    title: value.title
  };
}
