import { createRouter, createWebHashHistory } from 'vue-router';
import { nextTick } from 'vue';
import AppLayout from '@/layout/AppLayout.vue';
import posthog from 'posthog-js';
import { ROUTE_NAMES } from '@/constants/routes';
import { directus } from '@/service/directus';
import { posApi } from '@/service/TicketingApi';
import { usePartner } from '@/composables/partner/use-partner';
import { useProducts } from '@/composables/checkout/use-products';
import { useShoppingCart } from '@/composables/shopping-cart/use-shopping-cart';
import { useTerminals } from '@/composables/terminals/use-terminals';
import { setPointOfSaleTranslations } from '@/i18n';
import { AUTH_ROUTES } from './auth';

export const authRoutes = [
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.LOGIN_IMPERSONATE,
  ROUTE_NAMES.RESET_PASSWORD,
  ROUTE_NAMES.UPDATE_PASSWORD
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...AUTH_ROUTES,
    {
      path: '/',
      name: ROUTE_NAMES.HOME,
      redirect: { name: ROUTE_NAMES.CHECKOUT },
      component: AppLayout,
      beforeEnter: async (to, from, next) => {
        // After login
        const { setPartner } = usePartner();
        const { fetchProducts } = useProducts();
        const { setIntegrationId } = useShoppingCart();
        const { setTerminals } = useTerminals();

        // Initalization endpoint
        const { partner, products, translations, integration_id, terminals } =
          await posApi.posInitializationGet();
        setIntegrationId(integration_id);
        setPartner(partner);
        fetchProducts(products);
        setTerminals(terminals);

        // Load translations from the backend
        setPointOfSaleTranslations(partner.locale.code, translations);

        next();
      },
      children: [
        {
          path: '/checkout',
          name: ROUTE_NAMES.CHECKOUT,
          component: () => import('@/views/Checkout.vue')
        },
        {
          path: '/bookings',
          name: ROUTE_NAMES.BOOKINGS,
          component: () => import('@/views/Bookings.vue')
        },
        {
          path: '/settings',
          name: ROUTE_NAMES.SETTINGS,
          component: () => import('@/views/Settings.vue')
        },
        {
          path: '/not-found',
          name: ROUTE_NAMES.NOT_FOUND,
          component: () => import('@/views/NotFound.vue')
        },
        {
          path: '/not-found',
          name: ROUTE_NAMES.NOT_FOUND,
          component: () => import('@/views/NotFound.vue')
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/not-found'
    }
  ]
});

// Global guards
router.beforeEach(async (to) => {
  try {
    const desiredRouteName = to.name?.toString();
    if (!desiredRouteName) {
      return;
    }
    // Allow access to all /auth/* routes
    if (authRoutes.includes(desiredRouteName)) {
      return;
    }

    // Avoid infinite loop to the login page
    if (desiredRouteName === ROUTE_NAMES.LOGIN) {
      return;
    }

    // Make sure the user is authenticated before taking to the desired route
    const isLoggedIn = await directus.getToken();
    if (!isLoggedIn) {
      return { name: 'login' };
    }
  } catch (error) {
    return { name: 'login' };
  }
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture('$pageview', {
      $current_url: to.fullPath
    });
  });
});

export default router;
