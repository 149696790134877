/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductInfoProductMediaInner } from './ProductInfoProductMediaInner';
import {
  ProductInfoProductMediaInnerFromJSON,
  ProductInfoProductMediaInnerFromJSONTyped,
  ProductInfoProductMediaInnerToJSON
} from './ProductInfoProductMediaInner';
import type { ProductInfoProductOptionsWithTranslationsValue } from './ProductInfoProductOptionsWithTranslationsValue';
import {
  ProductInfoProductOptionsWithTranslationsValueFromJSON,
  ProductInfoProductOptionsWithTranslationsValueFromJSONTyped,
  ProductInfoProductOptionsWithTranslationsValueToJSON
} from './ProductInfoProductOptionsWithTranslationsValue';

/**
 *
 * @export
 * @interface ProductInfo
 */
export interface ProductInfo {
  /**
   *
   * @type {string}
   * @memberof ProductInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductInfo
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductInfo
   */
  important_information?: string | null;
  /**
   *
   * @type {Array<ProductInfoProductMediaInner>}
   * @memberof ProductInfo
   */
  product_media: Array<ProductInfoProductMediaInner>;
  /**
   *
   * @type {{ [key: string]: ProductInfoProductOptionsWithTranslationsValue; }}
   * @memberof ProductInfo
   */
  product_options_with_translations: {
    [key: string]: ProductInfoProductOptionsWithTranslationsValue;
  };
}

/**
 * Check if a given object implements the ProductInfo interface.
 */
export function instanceOfProductInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_media' in value;
  isInstance = isInstance && 'product_options_with_translations' in value;

  return isInstance;
}

export function ProductInfoFromJSON(json: any): ProductInfo {
  return ProductInfoFromJSONTyped(json, false);
}

export function ProductInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    important_information: !exists(json, 'important_information')
      ? undefined
      : json['important_information'],
    product_media: (json['product_media'] as Array<any>).map(ProductInfoProductMediaInnerFromJSON),
    product_options_with_translations: mapValues(
      json['product_options_with_translations'],
      ProductInfoProductOptionsWithTranslationsValueFromJSON
    )
  };
}

export function ProductInfoToJSON(value?: ProductInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    important_information: value.important_information,
    product_media: (value.product_media as Array<any>).map(ProductInfoProductMediaInnerToJSON),
    product_options_with_translations: mapValues(
      value.product_options_with_translations,
      ProductInfoProductOptionsWithTranslationsValueToJSON
    )
  };
}
