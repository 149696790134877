import { ROUTE_NAMES } from '@/constants/routes';

export const AUTH_ROUTES = [
  {
    path: '/auth/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/auth/reset-password',
    name: ROUTE_NAMES.RESET_PASSWORD,
    component: () => import('@/views/auth/ResetPassword.vue')
  },
  {
    path: '/auth/update-password',
    name: ROUTE_NAMES.UPDATE_PASSWORD,
    component: () => import('@/views/auth/UpdatePassword.vue')
  },
  {
    path: '/auth/login-impersonate',
    name: ROUTE_NAMES.LOGIN_IMPERSONATE,
    component: () => import('@/views/auth/LoginImpersonate.vue')
  }
];
