/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingReadResponsePricingCategoriesInnerPricePricingScalesInner
 */
export interface PricingReadResponsePricingCategoriesInnerPricePricingScalesInner {
  /**
   * number of participants from which the scale value applies
   * @type {number}
   * @memberof PricingReadResponsePricingCategoriesInnerPricePricingScalesInner
   */
  from_num_participants: number;
  /**
   * the price value for one participant at this pricing scale
   * @type {number}
   * @memberof PricingReadResponsePricingCategoriesInnerPricePricingScalesInner
   */
  value: number;
}

/**
 * Check if a given object implements the PricingReadResponsePricingCategoriesInnerPricePricingScalesInner interface.
 */
export function instanceOfPricingReadResponsePricingCategoriesInnerPricePricingScalesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'from_num_participants' in value;
  isInstance = isInstance && 'value' in value;

  return isInstance;
}

export function PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSON(
  json: any
): PricingReadResponsePricingCategoriesInnerPricePricingScalesInner {
  return PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSONTyped(json, false);
}

export function PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponsePricingCategoriesInnerPricePricingScalesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from_num_participants: json['from_num_participants'],
    value: json['value']
  };
}

export function PricingReadResponsePricingCategoriesInnerPricePricingScalesInnerToJSON(
  value?: PricingReadResponsePricingCategoriesInnerPricePricingScalesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from_num_participants: value.from_num_participants,
    value: value.value
  };
}
