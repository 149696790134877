/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductInfoProductOptionsWithTranslationsValuePartnerQuestions } from './ProductInfoProductOptionsWithTranslationsValuePartnerQuestions';
import {
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSON,
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSONTyped,
  ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsToJSON
} from './ProductInfoProductOptionsWithTranslationsValuePartnerQuestions';

/**
 *
 * @export
 * @interface ProductInfoProductOptionsWithTranslationsValue
 */
export interface ProductInfoProductOptionsWithTranslationsValue {
  /**
   *
   * @type {string}
   * @memberof ProductInfoProductOptionsWithTranslationsValue
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductInfoProductOptionsWithTranslationsValue
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductInfoProductOptionsWithTranslationsValue
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductInfoProductOptionsWithTranslationsValue
   */
  time_slot_type: ProductInfoProductOptionsWithTranslationsValueTimeSlotTypeEnum;
  /**
   *
   * @type {ProductInfoProductOptionsWithTranslationsValuePartnerQuestions}
   * @memberof ProductInfoProductOptionsWithTranslationsValue
   */
  partner_questions?: ProductInfoProductOptionsWithTranslationsValuePartnerQuestions;
}

/**
 * @export
 */
export const ProductInfoProductOptionsWithTranslationsValueTimeSlotTypeEnum = {
  StartingTime: 'starting_time',
  OpeningHours: 'opening_hours',
  TimeRange: 'time_range',
  Flexible: 'flexible'
} as const;
export type ProductInfoProductOptionsWithTranslationsValueTimeSlotTypeEnum =
  (typeof ProductInfoProductOptionsWithTranslationsValueTimeSlotTypeEnum)[keyof typeof ProductInfoProductOptionsWithTranslationsValueTimeSlotTypeEnum];

/**
 * Check if a given object implements the ProductInfoProductOptionsWithTranslationsValue interface.
 */
export function instanceOfProductInfoProductOptionsWithTranslationsValue(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'time_slot_type' in value;

  return isInstance;
}

export function ProductInfoProductOptionsWithTranslationsValueFromJSON(
  json: any
): ProductInfoProductOptionsWithTranslationsValue {
  return ProductInfoProductOptionsWithTranslationsValueFromJSONTyped(json, false);
}

export function ProductInfoProductOptionsWithTranslationsValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductInfoProductOptionsWithTranslationsValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_option_id: json['product_option_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    time_slot_type: json['time_slot_type'],
    partner_questions: !exists(json, 'partner_questions')
      ? undefined
      : ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsFromJSON(
          json['partner_questions']
        )
  };
}

export function ProductInfoProductOptionsWithTranslationsValueToJSON(
  value?: ProductInfoProductOptionsWithTranslationsValue | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_option_id: value.product_option_id,
    title: value.title,
    description: value.description,
    time_slot_type: value.time_slot_type,
    partner_questions: ProductInfoProductOptionsWithTranslationsValuePartnerQuestionsToJSON(
      value.partner_questions
    )
  };
}
