export const isDevelopment = import.meta.env.MODE === 'development';
export const isProduction = import.meta.env.MODE === 'production';
export const isTesting = import.meta.env.MODE === 'testing';

// VITE_POS_RELEASE_VERSION is generated while building the app in the github actions pipeline
// For more information please see .github/build.yml
export const POS_RELEASE_VERSION =
  import.meta.env.VITE_POS_RELEASE_VERSION || 'invalid-release-version';

export const DIRECTUS_API_URL: string = import.meta.env.VITE_DIRECTUS_API_URL;
export const POS_URL: string = import.meta.env.VITE_POS_URL;
