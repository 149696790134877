import { computed, ref } from 'vue';
import type { InitializationReadResponse1TerminalsInner } from '@openapi/models';
import { useLocalStorage } from '../storage/use-local-storage';
import { STORAGE_PREFIX } from '@/constants/storage';

const terminals = ref<InitializationReadResponse1TerminalsInner[]>();
const { getItem, setItem } = useLocalStorage();

export function useTerminals() {
  const terminalLocalStorageKey = `${STORAGE_PREFIX}terminals`;
  const loading = ref(false);
  const error = ref();

  const getActiveTerminalPoiId = computed(() => {
    const terminal = getItem(terminalLocalStorageKey);
    return terminal;
  });

  function setActiveTerminalPoiId(
    terminalPoiId: InitializationReadResponse1TerminalsInner['poi_id']
  ) {
    setItem(terminalLocalStorageKey, terminalPoiId);
  }

  function setTerminals(t: InitializationReadResponse1TerminalsInner[]) {
    const terminal = getItem(terminalLocalStorageKey);
    terminals.value = t;

    if (!terminal && terminals.value?.length > 0) {
      setItem(terminalLocalStorageKey, terminals.value[0].poi_id);
    }
  }

  function reset() {
    terminals.value = undefined;
  }

  return {
    loading,
    error,
    terminals,
    reset,
    setTerminals,
    getActiveTerminalPoiId,
    setActiveTerminalPoiId
  };
}
