import { createDirectus, authentication, rest } from '@directus/sdk';
import { DIRECTUS_API_URL } from '@/config';
class SessionStorage {
  SESSION_STORAGE_KEY = 'directus-auth';
  get() {
    const data = sessionStorage.getItem(this.SESSION_STORAGE_KEY);
    if (data === null) return null;
    return JSON.parse(data);
  }
  set(data: any) {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(data));
  }
}

export const directus = createDirectus(DIRECTUS_API_URL)
  .with(authentication('cookie', { credentials: 'include', storage: new SessionStorage() }))
  .with(rest());
