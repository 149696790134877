/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddToCartRequestPartnerQuestionAnswers } from './AddToCartRequestPartnerQuestionAnswers';
import {
  AddToCartRequestPartnerQuestionAnswersFromJSON,
  AddToCartRequestPartnerQuestionAnswersFromJSONTyped,
  AddToCartRequestPartnerQuestionAnswersToJSON
} from './AddToCartRequestPartnerQuestionAnswers';

/**
 *
 * @export
 * @interface AddToCartRequest
 */
export interface AddToCartRequest {
  /**
   * Currency code selected in the client application. Prices in the response are returned in the selected response_currency_code
   * @type {string}
   * @memberof AddToCartRequest
   */
  response_currency_code: string;
  /**
   *
   * @type {string}
   * @memberof AddToCartRequest
   */
  time_slot_id: string;
  /**
   *
   * @type {string}
   * @memberof AddToCartRequest
   */
  shopping_cart_hash: string;
  /**
   *
   * @type {object}
   * @memberof AddToCartRequest
   */
  participants_by_price: object;
  /**
   *
   * @type {AddToCartRequestPartnerQuestionAnswers}
   * @memberof AddToCartRequest
   */
  partner_question_answers?: AddToCartRequestPartnerQuestionAnswers;
  /**
   *
   * @type {string}
   * @memberof AddToCartRequest
   */
  locale_code?: string;
}

/**
 * Check if a given object implements the AddToCartRequest interface.
 */
export function instanceOfAddToCartRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'response_currency_code' in value;
  isInstance = isInstance && 'time_slot_id' in value;
  isInstance = isInstance && 'shopping_cart_hash' in value;
  isInstance = isInstance && 'participants_by_price' in value;

  return isInstance;
}

export function AddToCartRequestFromJSON(json: any): AddToCartRequest {
  return AddToCartRequestFromJSONTyped(json, false);
}

export function AddToCartRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    response_currency_code: json['response_currency_code'],
    time_slot_id: json['time_slot_id'],
    shopping_cart_hash: json['shopping_cart_hash'],
    participants_by_price: json['participants_by_price'],
    partner_question_answers: !exists(json, 'partner_question_answers')
      ? undefined
      : AddToCartRequestPartnerQuestionAnswersFromJSON(json['partner_question_answers']),
    locale_code: !exists(json, 'locale_code') ? undefined : json['locale_code']
  };
}

export function AddToCartRequestToJSON(value?: AddToCartRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    response_currency_code: value.response_currency_code,
    time_slot_id: value.time_slot_id,
    shopping_cart_hash: value.shopping_cart_hash,
    participants_by_price: value.participants_by_price,
    partner_question_answers: AddToCartRequestPartnerQuestionAnswersToJSON(
      value.partner_question_answers
    ),
    locale_code: value.locale_code
  };
}
