/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InitializationReadResponse1TerminalsInner
 */
export interface InitializationReadResponse1TerminalsInner {
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse1TerminalsInner
   */
  poi_id: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse1TerminalsInner
   */
  name: string;
}

/**
 * Check if a given object implements the InitializationReadResponse1TerminalsInner interface.
 */
export function instanceOfInitializationReadResponse1TerminalsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'poi_id' in value;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function InitializationReadResponse1TerminalsInnerFromJSON(
  json: any
): InitializationReadResponse1TerminalsInner {
  return InitializationReadResponse1TerminalsInnerFromJSONTyped(json, false);
}

export function InitializationReadResponse1TerminalsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponse1TerminalsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    poi_id: json['poi_id'],
    name: json['name']
  };
}

export function InitializationReadResponse1TerminalsInnerToJSON(
  value?: InitializationReadResponse1TerminalsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    poi_id: value.poi_id,
    name: value.name
  };
}
