/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingDataPricingCategoriesDataInner
 */
export interface BookingDataPricingCategoriesDataInner {
  /**
   *
   * @type {number}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  number_of_participants: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  individual_price: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  individual_price_pre_promo: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  total_price: number;
  /**
   *
   * @type {number}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  total_price_pre_promo: number;
  /**
   *
   * @type {string}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {string}
   * @memberof BookingDataPricingCategoriesDataInner
   */
  pricing_category_name: string;
}

/**
 * Check if a given object implements the BookingDataPricingCategoriesDataInner interface.
 */
export function instanceOfBookingDataPricingCategoriesDataInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'number_of_participants' in value;
  isInstance = isInstance && 'individual_price' in value;
  isInstance = isInstance && 'individual_price_pre_promo' in value;
  isInstance = isInstance && 'total_price' in value;
  isInstance = isInstance && 'total_price_pre_promo' in value;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'pricing_category_name' in value;

  return isInstance;
}

export function BookingDataPricingCategoriesDataInnerFromJSON(
  json: any
): BookingDataPricingCategoriesDataInner {
  return BookingDataPricingCategoriesDataInnerFromJSONTyped(json, false);
}

export function BookingDataPricingCategoriesDataInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingDataPricingCategoriesDataInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    number_of_participants: json['number_of_participants'],
    individual_price: json['individual_price'],
    individual_price_pre_promo: json['individual_price_pre_promo'],
    total_price: json['total_price'],
    total_price_pre_promo: json['total_price_pre_promo'],
    pricing_category_id: json['pricing_category_id'],
    pricing_category_name: json['pricing_category_name']
  };
}

export function BookingDataPricingCategoriesDataInnerToJSON(
  value?: BookingDataPricingCategoriesDataInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    number_of_participants: value.number_of_participants,
    individual_price: value.individual_price,
    individual_price_pre_promo: value.individual_price_pre_promo,
    total_price: value.total_price,
    total_price_pre_promo: value.total_price_pre_promo,
    pricing_category_id: value.pricing_category_id,
    pricing_category_name: value.pricing_category_name
  };
}
