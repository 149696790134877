/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddToCartResponseShoppingCartData } from './AddToCartResponseShoppingCartData';
import {
  AddToCartResponseShoppingCartDataFromJSON,
  AddToCartResponseShoppingCartDataFromJSONTyped,
  AddToCartResponseShoppingCartDataToJSON
} from './AddToCartResponseShoppingCartData';

/**
 *
 * @export
 * @interface AddToCartResponse
 */
export interface AddToCartResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddToCartResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof AddToCartResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof AddToCartResponse
   */
  exception_message?: string;
  /**
   *
   * @type {AddToCartResponseShoppingCartData}
   * @memberof AddToCartResponse
   */
  shopping_cart_data: AddToCartResponseShoppingCartData;
}

/**
 * Check if a given object implements the AddToCartResponse interface.
 */
export function instanceOfAddToCartResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'shopping_cart_data' in value;

  return isInstance;
}

export function AddToCartResponseFromJSON(json: any): AddToCartResponse {
  return AddToCartResponseFromJSONTyped(json, false);
}

export function AddToCartResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    shopping_cart_data: AddToCartResponseShoppingCartDataFromJSON(json['shopping_cart_data'])
  };
}

export function AddToCartResponseToJSON(value?: AddToCartResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    shopping_cart_data: AddToCartResponseShoppingCartDataToJSON(value.shopping_cart_data)
  };
}
