/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FinalizePaymentRequest
 */
export interface FinalizePaymentRequest {
  /**
   *
   * @type {string}
   * @memberof FinalizePaymentRequest
   */
  payment_mode?: FinalizePaymentRequestPaymentModeEnum;
  /**
   *
   * @type {string}
   * @memberof FinalizePaymentRequest
   */
  payment_method?: FinalizePaymentRequestPaymentMethodEnum;
  /**
   *
   * @type {string}
   * @memberof FinalizePaymentRequest
   */
  shopping_cart_hash: string;
}

/**
 * @export
 */
export const FinalizePaymentRequestPaymentModeEnum = {
  Adyen: 'adyen',
  PayOnsite: 'pay_onsite'
} as const;
export type FinalizePaymentRequestPaymentModeEnum =
  (typeof FinalizePaymentRequestPaymentModeEnum)[keyof typeof FinalizePaymentRequestPaymentModeEnum];

/**
 * @export
 */
export const FinalizePaymentRequestPaymentMethodEnum = {
  AdyenWeb: 'adyen_web',
  AdyenPos: 'adyen_pos',
  Cash: 'cash',
  GiftCode: 'gift_code',
  Other: 'other',
  ExternalDevice: 'external_device'
} as const;
export type FinalizePaymentRequestPaymentMethodEnum =
  (typeof FinalizePaymentRequestPaymentMethodEnum)[keyof typeof FinalizePaymentRequestPaymentMethodEnum];

/**
 * Check if a given object implements the FinalizePaymentRequest interface.
 */
export function instanceOfFinalizePaymentRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'shopping_cart_hash' in value;

  return isInstance;
}

export function FinalizePaymentRequestFromJSON(json: any): FinalizePaymentRequest {
  return FinalizePaymentRequestFromJSONTyped(json, false);
}

export function FinalizePaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinalizePaymentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    payment_mode: !exists(json, 'payment_mode') ? undefined : json['payment_mode'],
    payment_method: !exists(json, 'payment_method') ? undefined : json['payment_method'],
    shopping_cart_hash: json['shopping_cart_hash']
  };
}

export function FinalizePaymentRequestToJSON(value?: FinalizePaymentRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_mode: value.payment_mode,
    payment_method: value.payment_method,
    shopping_cart_hash: value.shopping_cart_hash
  };
}
