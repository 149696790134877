/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddToCartRequestPartnerQuestionAnswersBookingInner
 */
export interface AddToCartRequestPartnerQuestionAnswersBookingInner {
  /**
   *
   * @type {string}
   * @memberof AddToCartRequestPartnerQuestionAnswersBookingInner
   */
  partner_question_id: string;
  /**
   *
   * @type {string}
   * @memberof AddToCartRequestPartnerQuestionAnswersBookingInner
   */
  answer: string;
}

/**
 * Check if a given object implements the AddToCartRequestPartnerQuestionAnswersBookingInner interface.
 */
export function instanceOfAddToCartRequestPartnerQuestionAnswersBookingInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'partner_question_id' in value;
  isInstance = isInstance && 'answer' in value;

  return isInstance;
}

export function AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSON(
  json: any
): AddToCartRequestPartnerQuestionAnswersBookingInner {
  return AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSONTyped(json, false);
}

export function AddToCartRequestPartnerQuestionAnswersBookingInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartRequestPartnerQuestionAnswersBookingInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partner_question_id: json['partner_question_id'],
    answer: json['answer']
  };
}

export function AddToCartRequestPartnerQuestionAnswersBookingInnerToJSON(
  value?: AddToCartRequestPartnerQuestionAnswersBookingInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    partner_question_id: value.partner_question_id,
    answer: value.answer
  };
}
