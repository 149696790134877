import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { bootstrapApp } from '@/bootstrap';
import App from './App.vue';
import router from './router';
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import i18n from '@/i18n';
import PostHog from '@/plugins/posthog';
import Sanitize from '@/plugins/sanitize';
import InputTextValidation from '@/components/forms/InputTextValidation.vue';

// Import all styles (primevue, sakai, custom gyg theme)
import '@/assets/styles.scss';

const app = createApp(App);
const pinia = createPinia();

app.directive('tooltip', Tooltip);
app.component('InputTextValidation', InputTextValidation);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.use(PostHog);
app.use(Sanitize);

bootstrapApp(app).then(() => app.mount('#app'));
