/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInner } from './AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInner';
import {
  AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSON,
  AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSONTyped,
  AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInnerToJSON
} from './AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInner';

/**
 *
 * @export
 * @interface AddToCartRequestPartnerQuestionAnswersParticipantInner
 */
export interface AddToCartRequestPartnerQuestionAnswersParticipantInner {
  /**
   *
   * @type {string}
   * @memberof AddToCartRequestPartnerQuestionAnswersParticipantInner
   */
  partner_question_id: string;
  /**
   *
   * @type {Array<AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInner>}
   * @memberof AddToCartRequestPartnerQuestionAnswersParticipantInner
   */
  answers: Array<AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInner>;
}

/**
 * Check if a given object implements the AddToCartRequestPartnerQuestionAnswersParticipantInner interface.
 */
export function instanceOfAddToCartRequestPartnerQuestionAnswersParticipantInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'partner_question_id' in value;
  isInstance = isInstance && 'answers' in value;

  return isInstance;
}

export function AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSON(
  json: any
): AddToCartRequestPartnerQuestionAnswersParticipantInner {
  return AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSONTyped(json, false);
}

export function AddToCartRequestPartnerQuestionAnswersParticipantInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddToCartRequestPartnerQuestionAnswersParticipantInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partner_question_id: json['partner_question_id'],
    answers: (json['answers'] as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSON
    )
  };
}

export function AddToCartRequestPartnerQuestionAnswersParticipantInnerToJSON(
  value?: AddToCartRequestPartnerQuestionAnswersParticipantInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    partner_question_id: value.partner_question_id,
    answers: (value.answers as Array<any>).map(
      AddToCartRequestPartnerQuestionAnswersParticipantInnerAnswersInnerToJSON
    )
  };
}
