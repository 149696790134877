/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Checkout
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingReadRequestSearchInnerSearchRange } from './BookingReadRequestSearchInnerSearchRange';
import {
  BookingReadRequestSearchInnerSearchRangeFromJSON,
  BookingReadRequestSearchInnerSearchRangeFromJSONTyped,
  BookingReadRequestSearchInnerSearchRangeToJSON
} from './BookingReadRequestSearchInnerSearchRange';
import type { BookingReadRequestSearchInnerSearchTerm } from './BookingReadRequestSearchInnerSearchTerm';
import {
  BookingReadRequestSearchInnerSearchTermFromJSON,
  BookingReadRequestSearchInnerSearchTermFromJSONTyped,
  BookingReadRequestSearchInnerSearchTermToJSON
} from './BookingReadRequestSearchInnerSearchTerm';

/**
 *
 * @export
 * @interface BookingReadRequestSearchInner
 */
export interface BookingReadRequestSearchInner {
  /**
   *
   * @type {string}
   * @memberof BookingReadRequestSearchInner
   */
  column_name: string;
  /**
   *
   * @type {BookingReadRequestSearchInnerSearchTerm}
   * @memberof BookingReadRequestSearchInner
   */
  search_term?: BookingReadRequestSearchInnerSearchTerm;
  /**
   *
   * @type {BookingReadRequestSearchInnerSearchRange}
   * @memberof BookingReadRequestSearchInner
   */
  search_range?: BookingReadRequestSearchInnerSearchRange;
}

/**
 * Check if a given object implements the BookingReadRequestSearchInner interface.
 */
export function instanceOfBookingReadRequestSearchInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'column_name' in value;

  return isInstance;
}

export function BookingReadRequestSearchInnerFromJSON(json: any): BookingReadRequestSearchInner {
  return BookingReadRequestSearchInnerFromJSONTyped(json, false);
}

export function BookingReadRequestSearchInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingReadRequestSearchInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    column_name: json['column_name'],
    search_term: !exists(json, 'search_term')
      ? undefined
      : BookingReadRequestSearchInnerSearchTermFromJSON(json['search_term']),
    search_range: !exists(json, 'search_range')
      ? undefined
      : BookingReadRequestSearchInnerSearchRangeFromJSON(json['search_range'])
  };
}

export function BookingReadRequestSearchInnerToJSON(
  value?: BookingReadRequestSearchInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    column_name: value.column_name,
    search_term: BookingReadRequestSearchInnerSearchTermToJSON(value.search_term),
    search_range: BookingReadRequestSearchInnerSearchRangeToJSON(value.search_range)
  };
}
