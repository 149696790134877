import { usePartner } from '@/composables/partner/use-partner';
import { Temporal } from '@js-temporal/polyfill';

const { locale: partnerLocale, currency: partnerCurrency } = usePartner();

export function formatDate(date: Date) {
  const startDate = Temporal.PlainDate.from({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  });
  return startDate.toString();
}

export function formatTime(date: Date) {
  const startDate = Temporal.PlainTime.from({ hour: date.getHours(), minute: date.getMinutes() });
  return startDate.toString({ smallestUnit: 'minute' });
}

export const formatCurrency = (amount: number, locale?: string, currency?: string) => {
  return Intl.NumberFormat(locale || partnerLocale.value, {
    style: 'currency',
    currency: currency || partnerCurrency.value
  }).format(amount);
};
